<template>
  <NTooltip
    :show="props.showGuide"
    trigger="manual"
    placement="bottom"
  >
    <span>{{ _t('点击这里，可以回看上一张卡片哦') }}</span>

    <template #trigger>
      <Icon
        name="last-card"
        :class="[
          'w-20px text-ld-text cursor-pointer shrink-0',
          {
            'opacity-30': props.disabled,
          },
        ]"
        @click="onClick"
      />
    </template>
  </NTooltip>
</template>
<script setup lang="ts">
import { NTooltip } from 'naive-ui'

const props = defineProps<{
  disabled: boolean
  showGuide: boolean
}>()

const emit = defineEmits<{
  click: []
}>()

function onClick() {
  if (props.disabled) {
    _message.info(_t('这是第一张卡片，没什么可以回看的'))
    return
  }

  emit('click')
}
</script>
<style scoped></style>
