<template>
  <div class="flex flex-col justify-center items-center min-w-300px">
    <Icon
      name="close-circle"
      class="cursor-pointer absolute right-2 top-2 z-1 w-25px"
      @click="onRewardReceive"
    ></Icon>

    <RewardView
      class="w-150px h-150px"
      icon-name="diamonds"
    />

    <div class="font-semibold text-21px flex m-y-20px">
      <span>获得：</span>

      <template v-if="diamonds">
        <Icon
          name="diamond"
          class="w-30px h-30px mr-2px"
        ></Icon>
        <span class="text-ld-brand-500">钻石x{{ diamonds }}</span>
      </template>
    </div>

    <Button
      class="w-full"
      label="收下奖励"
      @click="onRewardReceive"
    ></Button>
  </div>
</template>

<script setup lang="ts">
import Icon from './Icon.vue'

import { computed } from 'vue'
import RewardView from './RewardView.vue'
import { RewardType, type Reward } from '@/api/task'
import { onMounted } from 'vue'
import { useCommonStore } from '@/stores'

const store = useCommonStore()

const props = defineProps<{
  rewards: Reward[]
}>()

const emit = defineEmits<{
  done: []
}>()

const diamonds = computed(() => {
  const diamondReward = props.rewards.find(
    item => item.type === RewardType.DIAMOND
  )
  return diamondReward?.value ?? 0
})

// 每次弹出奖励弹窗时，更新一下钻石数量
onMounted(() => {
  store.fetchUserAssets()
  store.fetchStatsOverview()
})

function onRewardReceive() {
  emit('done')
}
</script>

<style scoped></style>
