<template>
  <div class="bg-ld-background p-4 flex-1 flex flex-col overflow-hidden">
    <div
      v-if="closeable"
      class="relative flex items-center h-24px mb-4"
    >
      <span
        v-if="props.title"
        class="text-ld-label-secondary text-17px font-medium mx-auto"
      >
        {{ props.title }}
      </span>

      <Icon
        name="close-circle"
        class="cursor-pointer w-25px absolute right-0"
        @click="emit('done')"
      ></Icon>
    </div>

    <div class="flex-1 overflow-auto">
      <NewKnowledgeIcon
        v-if="props.isFirstLearn"
        class="mx-auto mb-4"
      />

      <ClozeCardDetail
        v-if="props.card.type === CardType.CLOZE"
        :card="props.card"
      />

      <WordCardDetail
        v-if="props.card.type === CardType.EN_WORD"
        :card="props.card"
      />

      <MCQCardDetail
        v-if="props.card.type === CardType.MCQ"
        :card="props.card"
      />
    </div>

    <div class="w-full mt-4">
      <Button
        :label="props.primaryButtonText"
        class="w-full"
        @click="onNext"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Card, CardType } from '@/types/core'
import ClozeCardDetail from './ClozeCardDetail.vue'
import WordCardDetail from './EnWordCardDetail.vue'
import MCQCardDetail from './MCQCardDetail.vue'
import NewKnowledgeIcon from './NewKnowledgeIcon.vue'
import { useHotKey } from '@/hooks'

useHotKey('enter,space', () => {
  onNext()
})

const props = withDefaults(
  defineProps<{
    card: Card
    isFirstLearn: boolean
    primaryButtonText?: string
    closeable?: boolean
    title?: string
  }>(),
  {
    primaryButtonText: _t('下一张'),
    closeable: false,
    title: undefined,
  }
)

const emit = defineEmits<{
  done: []
}>()

function onNext() {
  emit('done')
}
</script>

<style scoped></style>
