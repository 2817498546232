<template>
  <div class="flex flex-col items-center justify-center">
    <template v-if="props.icon">
      <Img
        v-if="props.icon.type === 'img'"
        :name="props.icon.name"
        class="w-85px"
      />
      <Icon
        v-else
        class="w-80px h-80px"
        :name="props.icon.name"
      />
    </template>

    <Img
      v-else
      name="ld-greet"
      class="w-85px mb-3"
    />

    <template v-if="props.title">
      <div
        class="text-19px font-semibold text-[var(--text-color-primary)] text-center mb-2"
      >
        {{ props.title }}
      </div>

      <div
        class="text-17px font-semibold text-ld-label-secondary text-center whitespace-pre-line"
      >
        {{ props.content }}
      </div>
    </template>

    <div
      v-else
      class="text-19px font-semibold text-[var(--text-color-primary)] text-center whitespace-pre-line"
    >
      {{ props.content }}
    </div>

    <Button
      class="whitespace-nowrap w-full mt-5"
      :label="props.primaryText"
      :loading="primaryButtonLoading"
      @click="onPrimaryButtonClick()"
    ></Button>

    <div class="h-8px"></div>

    <Button
      class="whitespace-nowrap w-full"
      :label="props.secondaryText"
      :scene="secondaryButtonScene"
      :loading="secondaryButtonLoading"
      @click="onSecondaryButtonClick()"
    ></Button>
  </div>
</template>

<script lang="ts" setup>
import Icon from './Icon.vue'
import { computed, ref } from 'vue'

import type { ConfirmOptions, ConfirmResolveCallback } from '@/types/global'

const props = withDefaults(
  defineProps<{
    scene?: ConfirmOptions['scene']
    icon?: {
      name: string
      type: 'svg' | 'img'
    }
    title?: string
    content: string
    primaryText?: string
    secondaryText?: string
  }>(),
  {
    scene: 'confirm',
    icon: undefined,
    title: undefined,
    primaryText: _t('确认'),
    secondaryText: _t('取消'),
  }
)

const emit = defineEmits<{
  primaryClick: [ConfirmResolveCallback]
  secondaryClick: [ConfirmResolveCallback]
  done: []
}>()

const primaryButtonLoading = ref(false)
const secondaryButtonLoading = ref(false)

const secondaryButtonScene = computed(() =>
  props.scene === 'confirm' ? 'secondary' : 'danger'
)

function onPrimaryButtonClick() {
  primaryButtonLoading.value = true

  emit('primaryClick', function (resolved: boolean) {
    primaryButtonLoading.value = false

    if (resolved) {
      emit('done')
    }
  })
}

function onSecondaryButtonClick() {
  secondaryButtonLoading.value = false

  emit('secondaryClick', function (resolved: boolean) {
    secondaryButtonLoading.value = false

    if (resolved) {
      emit('done')
    }
  })
}
</script>

<style scoped></style>
