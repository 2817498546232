<template>
  <Loading />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { wxLogin, wxRegister } from '@/api/auth'
import { useCommonStore } from '@/stores'
import { setDebugInitialUser } from '@/debug'

const router = useRouter()
const route = useRoute()
const code = route.query.code as string
const redirect = route.query.redirect as string
const store = useCommonStore()

function exit() {
  router.replace(redirect ?? '/')
}

onMounted(async () => {
  // 异常情况，code 不存在，忽略这个错误，直接跳转到首页
  if (!code) {
    _reportError('wechat redirect page code not exist')
    exit()
    return
  }

  const wechatType = 'APP'

  wxLogin({ code, wechatType: wechatType })
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      _db.wxOpenId = res.data.wxOpenId
      // 已经注册了
      if (res.data.loginResponse) {
        setDebugInitialUser(res.data.loginResponse.user.id)
        store.login(res.data.loginResponse.token, res.data.loginResponse.user)
        return
      }

      // 自动注册
      return wxRegister({ wxToken: res.data.wxToken }).then(res => {
        if (res.code !== 0) {
          _message.info(res.message)
          return
        }

        store.login(res.data.token, res.data.user)
      })
    })
    .then(() => {
      exit()
    })
})

//
</script>

<style scoped></style>
