import { applePayNotify } from '@/api/order'

export type AppBridge = typeof bridge

export enum Platform {
  Android = 'android',
  IOS = 'ios',
}
export type AppInfo = {
  platform: Platform
  isReviewing: boolean
  fullVersion: string
  channel: string
  updateConfig?: {
    force: boolean
    title: string
    content: string
    link: string
    latestVersion: string
    forManualCheck: boolean
  }
  insets?: {
    top: number
    bottom: number
    left: number
    right: number
  }
}
type AppImageFile = {
  path: string
  name: string
  bytes: number[]
}

export type IAPItem = {
  productId?: string
  localizedPrice?: string
  title?: string
}

const channel = window.flutter_inappwebview

const bridge = {
  // 获取应用信息
  getAppInfo(): Promise<AppInfo> {
    return flutter_inappwebview.callHandler('getAppInfo')
  },

  // 微信是否安装
  isWeChatInstalled(): Promise<boolean> {
    return channel.callHandler('isWeChatInstalled')
  },

  // 支付宝是否安装
  isAlipayInstalled(): Promise<boolean> {
    return channel.callHandler('isAliPayInstalled')
  },

  // apple 授权
  appleAuth(): Promise<string> {
    return channel.callHandler('appleAuth')
  },

  // 微信授权，返回 code, 空表示用户取消
  wechatAuth(): Promise<string> {
    return channel.callHandler('wechatAuth')
  },

  // 微信支付
  wechatPay(params: {
    appId: string
    partnerId: string
    prepayId: string
    packageValue: string
    nonceStr: string
    timestamp: number
    sign: string
    signType?: string
    extData?: string
  }): Promise<unknown> {
    return channel.callHandler('wechatPay', params)
  },

  // 支付宝支付 使用前需要调用 isAlipayInstalled 判断是否安装支付宝
  // resultStatus 9000 表示支付成功 memo 是描述信息
  // 详情内容查看支付宝文档 https://opendocs.alipay.com/open/204/105302
  aliPay(params: { order: string }): Promise<{
    resultStatus: string
    memo: string
  }> {
    return channel.callHandler('aliPay', params)
  },

  // 请求相册权限
  requestPhotosPermission(): Promise<boolean> {
    return flutter_inappwebview.callHandler('requestPhotosPermission')
  },
  // 请求相机权限
  requestCameraPermission(): Promise<boolean> {
    return flutter_inappwebview.callHandler('requestCameraPermission')
  },

  // 打开 app 设置页面
  openAppSettings(): Promise<unknown> {
    return flutter_inappwebview.callHandler('openAppSettings')
  },

  // 选择图片 camera: true 拍照，false 相册 （不包含权限判断）
  async pickImage(params: { camera: boolean }): Promise<File | null> {
    const res: AppImageFile | null = await channel.callHandler(
      'pickImage',
      params
    )
    if (res == null) return null

    const uint8ArrayData = new Uint8Array(res.bytes)
    const ext = res.name.split('.').pop()?.toLowerCase()
    let type: string | undefined
    switch (ext) {
      case 'jpg':
      case 'jpeg':
        type = 'image/jpeg'
        break
      case 'png':
        type = 'image/png'
        break
      case 'gif':
        type = 'image/gif'
        break
      case 'svg':
        type = 'image/svg+xml'
        break
      case 'bmp':
        type = 'image/bmp'
        break
      case 'webp':
        type = 'image/webp'
        break
    }

    const blob = new Blob([uint8ArrayData], { type: type })
    return new File([blob], res.name, { type: blob.type })
  },

  // 购买 IAP 商品
  async iapBuyProduct(
    productId: string,
    orderId: string
  ): Promise<{
    ok: boolean
    msg: string
  }> {
    return channel.callHandler('iapBuyProduct', productId, orderId)
  },

  // 弹出 app debug 页面
  async showDebugPage(): Promise<void> {
    return channel.callHandler('showDebugPage')
  },

  // Web 已经加载完毕
  async webReady(): Promise<void> {
    return channel.callHandler('webReady')
  },

  // launch
  async launch(url: string): Promise<void> {
    return channel.callHandler('launch', url)
  },

  // 检查更新 true 有更新 false 无更新
  async checkUpdate(): Promise<boolean> {
    return channel.callHandler('checkUpdate')
  },

  // 苹果支付回调
  async applePayNotify(orderId: string, transactionId: string) {
    const res = await applePayNotify(orderId, transactionId)
    return {
      code: res.code,
      message: res.message,
    }
  },
  // 刷新 webview 页面
  async reloadWebview() {
    return channel.callHandler('reloadWebview')
  },
  // 禁用返回手势
  async disableBackGesture() {
    return channel.callHandler('disableBackGesture')
  },
  // 启用返回手势
  async enableBackGesture() {
    return channel.callHandler('enableBackGesture')
  },
}

export default bridge
