<template>
  <CheckInView
    v-if="checkData != null"
    class="min-h-[var(--ld-viewport-height)]"
    :check-data="checkData"
    @done="onBack"
  ></CheckInView>
</template>

<script setup lang="ts">
// TODO(baifan): 这里不应该使用任何 api 而是 mock 数据去测试组件
import {
  checkInStats,
  type CheckInCtRes,
  type CheckInStatsItem,
} from '@/api/user'
import CheckInView from '@/components/CheckInView.vue'
import { useCommonStore } from '@/stores'
import { formatDate } from '@/utils'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useCommonStore()

const checkData = ref<{
  checkInCtRes: CheckInCtRes
  stats: CheckInStatsItem[]
} | null>(null)
getCheckData()

async function getCheckData() {
  const checkInCtRes = await store.fetchCheckInCtRes()
  // 本周签到数据

  const now = new Date()
  const week = now.getDay()
  // 本周第一天
  const currentWeekFirstDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - (week == 0 ? 7 : week) + 1
  )

  // 本周最后一天
  const currentMonthLastDay = new Date(currentWeekFirstDay)
  currentMonthLastDay.setDate(currentWeekFirstDay.getDate() + 6)
  const stats = (
    await checkInStats(
      formatDate(currentWeekFirstDay, 'YYYY-MM-DD'),
      formatDate(currentMonthLastDay, 'YYYY-MM-DD')
    )
  ).stats

  checkData.value = {
    checkInCtRes,
    stats,
  }
}

function onBack() {
  router.push({
    name: 'shelf',
  })
}
</script>

<style scoped></style>
