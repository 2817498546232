<template>
  <div
    class="flex items-center bg-ld-brand-200 p-3 justify-between cursor-pointer"
    @click="onPackageUnlock"
  >
    <div class="text-ld-brand-600 text-15px">
      {{ _t('卡包试学中，仅限浏览') }}
    </div>

    <Button
      label="解锁完整版"
      :size="isPcMode ? 'normal' : 'small'"
    ></Button>
  </div>
</template>
<script setup lang="ts">
import type { PackageAccess, PackageBasic } from '@/api/package-source'
import PackageUnlock from './PackageUnlock.vue'

const isPcMode = _global.isPcMode

const props = defineProps<{
  package: PackageBasic
}>()

const emit = defineEmits<{
  unlock: [PackageAccess]
}>()

function onPackageUnlock() {
  _presentContent(PackageUnlock, {
    rootClass: 'max-h-600px min-w-400px max-w-600px',
    props: {
      package: props.package,
      onUnlock: (access: PackageAccess) => emit('unlock', access),
    },
    dialog: {
      pt: {
        content: {
          style: 'padding: 0px;',
        },
      },
    },
  })
}
</script>
<style scoped></style>
