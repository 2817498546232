import {
  ClozeCardFaceType,
  EnWordCardFaceType,
  LearnStatus,
  UnitEventType,
} from '@/types/core'
import api from './base'

import type { CommonResponse } from './base'
import type { DailyTask, GoalType } from './task'
import type { AtlasStageMode } from './atlas'

export interface CardSchedule {
  cardId: number
  cardStatus: LearnStatus
  content: string
  authorId: string
  r: number
}

export interface Unit {
  unitId: number
  // 表示当前队列是一个调试队列
  isDebug?: boolean
  atlasStageId?: number
  schedules: CardSchedule[]
}

export function fetchUnitScheduleByChapter(payload: { packageId: number }) {
  return api.post<never, CommonResponse<Unit>>(`/v1/learn/units`, payload)
}

export interface UnitEvent {
  event: UnitEventType
  face?: ClozeCardFaceType | EnWordCardFaceType
  timestamp: number
}

interface UnitCompleteReportPayload {
  unitId: number
  learnEvents: {
    [cardId in string]: UnitEvent[]
  }
  challengeStageParam?: {
    duelParam: {
      // 百分比小数 eg. 0.5 代表剩余 50% 血量
      leftBlood: number
    }
  }
  // 卡片学习时长，key 为卡片 id，value 为秒
  cardDurations: Record<number, number>

  // 卡片学习次数，key 为卡片 id，value 为学了多少次
  cardLearnTimes: Record<number, number>

  // 最大连击数
  comboCount: number
}

interface UnitCancelReportPayload {
  unitId: number
  learnEvents: {
    [cardId in string]: UnitEvent[]
  }
}

export interface UnitCompleteStat {
  unitId: number
  debutCount: number
  reviewCount: number
  comboCount: number
  duration: number
  exp: number
  comboExp: number
  star: number
  checkInPopped: boolean
}

export interface DailyTaskResponse {
  tasks: DailyTask[]
  lastStats: Record<GoalType, number>
  stats: Record<GoalType, number>
}

export function reportUnitComplete(payload: UnitCompleteReportPayload) {
  return api.post<
    never,
    {
      stats: UnitCompleteStat
      dailyTaskResponse: DailyTaskResponse
      mode?: AtlasStageMode
    }
  >(`/v1/learn/units/report-complete`, payload)
}

export function reportUnitCancel(payload: UnitCancelReportPayload) {
  return api.post<never, never>(`/v1/learn/units/report-cancel`, payload)
}

export enum StageType {
  NORMAL = 'NORMAL',
  QUIZ = 'QUIZ',
}

export interface Stage {
  id: number
  type: StageType
  createdAt: string
}

export interface CompletedStage extends Stage {
  completedAt: string
}

export enum StageStatus {
  OK = 'OK',
  WAIT = 'WAIT',
  NEED_VIP = 'NEED_VIP',
  CHALLENGE_COMPLETED = 'CHALLENGE_COMPLETED',
}

export interface CurrentStage {
  status: StageStatus
  dayToWait: number
  data?: Stage
}

export interface DictEnWord {
  spelling: string
  explain: string
  phoneticUk: string
  phoneticUs: string
}

export function searchEnWord(keyword: string) {
  return api.get<never, { dictEnList: DictEnWord[] }>(`/v1/dict/en-search`, {
    params: {
      keyword,
    },
  })
}
