<template>
  <div :id="id"></div>
</template>

<script lang="ts" setup>
// 用于 PC 扫码登录
import { IframeMessage } from '@/types/iframe'
import { randomString, tryJSONParse } from '@/utils'
import { onMounted, onUnmounted } from 'vue'

const emit = defineEmits<{
  // 返回微信扫码得到的 code
  code: [string]
}>()

function onWxCallback(event: MessageEvent) {
  const { type, data } = tryJSONParse(event.data, {})
  if (type !== IframeMessage.WX_GET_CODE) return
  emit('code', data.code)
}

onMounted(() => {
  window.addEventListener('message', onWxCallback)
  renderQrcode()
})
onUnmounted(() => {
  window.removeEventListener('message', onWxCallback)
})

const id = 'wx-qrcode-' + Date.now() + '-' + randomString(6)

function renderQrcode() {
  const prefix = _global.prodGatewayPrefix
  new WxLogin({
    self_redirect: true,
    id: id,
    appid: _global.wxAppId,
    scope: 'snsapi_login',
    redirect_uri: location.protocol + `//lingduck.cn${prefix}/wxcallback`,
    state: 'SCOPE',
  })
}
</script>

<style scoped></style>
