<template>
  <div class="flex flex-col justify-center items-center min-w-300px gap-4">
    <div class="w-full flex items-center relative">
      <span class="mx-auto">{{ _t('好友助力') }}</span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-0 w-22px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div
      :class="[
        'w-full flex flex-col gap-4',
        {
          'opacity-0': config == null,
        },
      ]"
    >
      <div class="section">
        <div class="title">{{ _t('助力方式') }}</div>

        <div class="text-15px leading-[1.8] bg-gray-100 rounded-12px p-3">
          <div>
            {{ _t('① 请好友在「奖励」页面双击鸭鸭， 输入下方的接头暗号') }}
          </div>
          <div class="text-ld-brand-500">
            {{ _t(`（好友注册 ${newUserHours} 小时内有效，仅限一次）`) }}
          </div>
          <div>{{ _t('② 暗号对接无误即可完成助力、获取奖励') }}</div>
          <div class="text-ld-brand-500">
            {{
              _t(
                `（你的面包上限+${config?.inviterEnergyLimit ?? ''}、好友获得 ${
                  config?.inviteeGetDiamond ?? ''
                } 钻石）`
              )
            }}
          </div>
        </div>
      </div>
      <div class="section">
        <div class="title">{{ _t('接头暗号') }}</div>
        <div
          class="bg-gray-100 rounded-12px flex items-center justify-between p-3"
        >
          <div class="font-[DIN] tracking-2px text-28px font-bold">
            {{ config?.code }}
          </div>
          <Button
            :label="_t('复制')"
            @click="onCopy"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { fetchInvitationConfig, type RcodeInvitationConfig } from '@/api/rcode'
import { useClipboard } from '@vueuse/core'

const emit = defineEmits<{
  done: []
}>()

const config = ref<RcodeInvitationConfig>()
const { copy } = useClipboard()

const newUserHours = computed(() => {
  if (config.value == null) return 0

  return Math.floor(config.value.newUserDuration / 3600)
})

async function onCopy() {
  if (config.value == null) return

  copy(config.value.code).then(() => {
    _message.info(_t('接头暗号复制成功，快去邀请好友吧'))
  })
}

onInit(() => {
  fetchInvitationConfig().then(res => (config.value = res))
})
</script>
<style scoped>
.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section .title {
  color: var(--ld-label-secondary);
  font-size: 17px;
  font-weight: 600;
}
</style>
