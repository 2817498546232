<template>
  <div
    v-if="props.clickable"
    class="flex items-center cursor-pointer"
    @click="onDiamondClick"
  >
    <DotBadge
      :visible="showDot"
      class="flex items-center"
      :dotStyle="{
        top: 0,
        right: 0,
      }"
    >
      <Icon
        name="diamond"
        class="w-28px"
      />
    </DotBadge>

    <div
      class="diamond-count"
      :style="{
        fontSize: isDiamondCountFontSizeSmaller ? '14px' : '16px',
      }"
    >
      {{ diamondAmountDisplay }}
    </div>
    <div class="diamond-increase-btn">
      <i class="pi pi-plus text-14px font-semibold"></i>
    </div>
  </div>

  <div
    v-else
    class="flex items-center"
  >
    <Icon
      name="diamond"
      class="w-24px"
    />

    <ColorNumber
      :value="diamondAmount"
      color="brand"
      class="ml-1px"
      :font-width="10"
    />
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores/common-store'
import { computed } from 'vue'
import ColorNumber from './ColorNumber.vue'
import DotBadge from '@/components/DotBadge.vue'
import { floor } from 'lodash-es'
import NotEnoughDiamonds from '@/components/NotEnoughDiamonds.vue'

const props = withDefaults(
  defineProps<{
    clickable?: boolean
  }>(),
  {
    clickable: false,
  }
)

const store = useCommonStore()

const diamondAmount = computed(() => store.userAssets?.diamond ?? 0)

const isDiamondCountFontSizeSmaller = computed(
  () => diamondAmount.value.toString().length > 4
)

// 是否显示红点
const showDot = computed(() => {
  // 有钻石卡，且今天未领取
  const todayNotReceived =
    store.diamondCard != null && !store.diamondCard.receivedToday
  return todayNotReceived
})

const diamondAmountDisplay = computed(() => {
  // 最大只显示到 999w，不考虑 8 位数
  // 到 7 位数之后，显示 XXXw，末位向下取整
  // 到 6 位数之后，显示 XX.Xw，末位向下取整
  if (diamondAmount.value >= 9999999) {
    return '999w'
  } else if (diamondAmount.value > 999999) {
    return `${floor(diamondAmount.value / 10000)}w`
  } else if (diamondAmount.value > 99999) {
    return `${floor(diamondAmount.value / 10000, 1)}w`
  }

  return diamondAmount.value.toString()
})

function onDiamondClick() {
  _presentContent(NotEnoughDiamonds, {
    rootClass: 'min-w-400px max-w-600px',
    dialog: {
      pt: {
        content: {
          style: 'padding: 0px;',
        },
      },
    },
  })
}
</script>
<style scoped>
.diamond-count {
  width: 62px;
  height: 24px;
  background: var(--ld-energyBg);
  border: 2px solid white;
  color: white;
  font-family: DIN;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  padding-left: 2px;
}

.diamond-increase-btn {
  width: 24px;
  height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ld-brand-400);
  border-radius: 8px;
  border-bottom: 2px solid var(--ld-energyBg);
  margin-left: -8px;
}

.diamond-increase-btn:active {
  border-bottom: none;
  margin-top: 2px;
}
</style>
