<template>
  <div
    ref="padEl"
    class="pad g-ld-shadow"
  >
    <div class="text-15px text-[var(--text-color-secondary)] mb-2">
      {{ isNeverLearned ? _t('尚未学习 ') : `已学习 ${props.learnCount} 次` }}
    </div>

    <CardEssence :card="card" />
  </div>
</template>
<script setup lang="ts">
import type { CardResponse } from '@/api/package-source'

import { type Card } from '@/types/core'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import bus, { BusEvent } from '@/bus/bus'
import CardEssence from '@/components/CardEssence/CardEssence.vue'

const props = defineProps<{
  cardResponse?: CardResponse
  card?: string
  learnCount?: number
}>()

const padEl = ref<HTMLDivElement>()

const card = computed(() => {
  if (props.cardResponse) {
    return JSON.parse(props.cardResponse.content) as Card
  }
  if (props.card) {
    return JSON.parse(props.card) as Card
  }

  return {} as Card
})

const isNeverLearned = computed(
  () => props.learnCount == null || props.learnCount === 0
)

function onFocus(id: number) {
  if (id === props.cardResponse?.id) {
    padEl.value?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }
}

onMounted(() => {
  bus.on(BusEvent.CardFocus, onFocus)
})

onUnmounted(() => {
  bus.off(BusEvent.CardFocus, onFocus)
})
</script>
<style scoped>
.pad {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  padding: 12px 16px;
  border: 1px solid var(--ld-border);
  transition: all 0.3s;
  background-color: white;
}
</style>
