<template>
  <div
    ref="treeRef"
    class="tree"
    @dragleave="onDragLeave"
  >
    <div
      v-for="element in props.nodes"
      :key="element.key"
      class="relative w-full overflow-hidden"
    >
      <div
        v-if="!element.root"
        :class="[
          'dragposition before',
          {
            visible:
              draggingOverNode?.key === element.key &&
              draggingOverPosition === 'before',
          },
        ]"
        @dragenter="onDragEnter(element, 'before')"
        @dragover.prevent
      >
        <div class="content"></div>
      </div>
      <div
        :class="[
          'treenode',
          {
            dragover:
              draggingOverNode?.key === element.key &&
              draggingOverPosition === 'in',
          },
        ]"
        :draggable="!element.root"
        @click="onSelectionKeysUpdate(element)"
        @dragstart="onDragStart($event, element)"
        @dragend="onDragEnd"
        @dragenter="onDragEnter(element, 'in')"
        @dragover.prevent
      >
        <span
          v-if="!element.leaf && !element.root"
          class="icon min-w-[24px] h-[24px] mr-2"
        >
          <i
            :class="[
              'pi pi-chevron-right  text-gray-400 transition',
              {
                expanded: props.expandedKeys[element.key],
              },
            ]"
            @click.stop="onNodeExpandToggle(element)"
          ></i>
        </span>

        <slot :node="element"></slot>
      </div>

      <Tree
        v-if="element.children && props.expandedKeys[element.key]"
        :draggable="props.draggable"
        :parent-node="element"
        :nodes="element.children"
        :selection-keys="props.selectionKeys"
        :expanded-keys="props.expandedKeys"
        :draggingOverNode="props.draggingOverNode"
        :draggingOverPosition="props.draggingOverPosition"
        class="pl-4"
        @update:selection-keys="emit('update:selection-keys', $event)"
        @update:expanded-keys="emit('update:expanded-keys', $event)"
        @node-expand="onNodeExpandToggle"
        @drag-start="emit('drag-start', $event)"
        @drag-end="onDragEnd"
        @drag-enter="onDragEnter"
      >
        <template #default="{ node }">
          <slot :node="node"></slot>
        </template>
      </Tree>
    </div>

    <div
      v-if="props.nodes.length > 0"
      :class="[
        'dragposition bottom',
        {
          visible:
            draggingOverNode?.key === lastNode?.key &&
            draggingOverPosition === 'bottom',
        },
      ]"
      @dragenter="onDragEnter(lastNode, 'bottom')"
      @dragover.prevent
    >
      <div class="content"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'

export interface TreeNode {
  key: string
  label: string
  root?: boolean
  leaf?: boolean
  loading?: boolean
  children?: TreeNode[]
}

export type DragPosition = 'in' | 'before' | 'bottom'

const props = defineProps<{
  draggable: boolean
  parentNode?: TreeNode
  nodes: TreeNode[]
  selectionKeys: Record<string, boolean>
  expandedKeys: Record<string, boolean>
  draggingOverNode?: TreeNode
  draggingOverPosition?: DragPosition
}>()

const emit = defineEmits<{
  'update:selection-keys': [Record<string, boolean>]
  'update:expanded-keys': [Record<string, boolean>]
  'node-expand': [TreeNode]
  'drag-enter': [TreeNode, DragPosition]
  'drag-leave': []
  'drag-start': [TreeNode]
  'drag-end': [DragEvent]
}>()

const treeRef = ref<HTMLDivElement>()

const lastNode = computed(() => props.nodes[props.nodes.length - 1])

function onNodeExpandToggle(node: TreeNode) {
  const expandedKeys = { ...props.expandedKeys }
  expandedKeys[node.key] = !expandedKeys[node.key]

  emit('update:expanded-keys', expandedKeys)

  if (expandedKeys[node.key]) {
    emit('node-expand', node)
  }
}

function onSelectionKeysUpdate(node: TreeNode) {
  emit('update:selection-keys', {
    [node.key]: true,
  })
}

function onDragStart(e: DragEvent, node: TreeNode) {
  if (!props.draggable) {
    e.preventDefault()
    e.stopPropagation()
    return
  }

  emit('drag-start', node)
}

function onDragEnd(e: DragEvent) {
  e.preventDefault()

  if (!props.draggable) return

  emit('drag-end', e)
}

function onDragEnter(node: TreeNode, position: DragPosition) {
  if (!props.draggable || node.root) return

  emit('drag-enter', node, position)
}

function onDragLeave(e: DragEvent) {
  if (!treeRef.value || props.parentNode) return

  const treeRect = treeRef.value.getBoundingClientRect()

  // 当 dragend 的时候，会触发一次 leave，此时 x 和 y 是 0
  if (e.x === 0 && e.y === 0) return

  if (
    e.y > treeRect.bottom ||
    e.y < treeRect.top ||
    e.x > treeRect.right ||
    e.x < treeRect.left
  ) {
    emit('drag-leave')
  }
}
</script>

<style scoped>
.treenode {
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-width: 2px;
  width: 100%;
}

.treenode.selected,
.treenode:hover.selected {
  color: var(--primary-color);
  background-color: var(--primary-100);
}

.treenode:hover {
  background-color: var(--primary-50);
}

.treenode.dragover {
  border: 1px solid var(--primary-color);
  border-width: 2px;
}

.dragposition {
  width: 100%;
  padding: 1px 0px;
}

.dragposition.bottom {
  top: unset;
  bottom: 0px;
}

.dragposition .content {
  width: 100%;
  height: 2px;
}

.dragposition.visible .content {
  background-color: var(--primary-color);
}

i.expanded {
  transform: rotate(90deg);
}
</style>
