<template>
  <div v-if="parsed.error">
    {{ _t('卡片解析失败') }}
  </div>

  <template v-else>
    <div class="bg-white p-3 rounded-8px">
      <div
        class="cursor-pointer"
        @click="onWordPlay"
      >
        <div class="font-semibold text-24px leading-[1] mb-1">
          {{ parsed.card.word }}
        </div>

        <div class="flex items-center">
          <span
            v-if="usPron"
            class="text-17px text-ld-label-secondary mr-1"
          >
            {{ usPron.label }}
          </span>

          <Icon
            name="pron"
            class="w-16px text-ld-brand-600"
          />
        </div>
      </div>

      <div class="text-19px leading-[1.5] mt-2">
        {{ parsed.card.definition }}
      </div>

      <Img
        v-if="card.illustration"
        :assetId="card.illustration"
        :class="[
          'rounded-4px mt-2 cursor-pointer transition-width select-none',
          showLargeIllustration ? 'w-full' : 'w-100px',
        ]"
        @click.stop="toggleIllustrationSize"
      />
    </div>

    <div
      v-if="card.examples.length > 0"
      class="flex flex-col gap-4 mt-4"
    >
      <div
        v-for="(item, i) in highlightedExamples"
        :key="i"
        class="bg-white p-3 rounded-8px flex items-start cursor-pointer"
        @click="onExamplePlay(i)"
      >
        <div class="flex-1 text-17px">
          <div>
            <span
              v-for="(span, i) of item.highlightedSpans"
              :key="i"
              :class="{
                'text-ld-brand-600 font-semibold': span.highlight,
              }"
            >
              {{ span.text }}
            </span>
          </div>
          <div>{{ item.translation }}</div>
        </div>

        <Icon
          name="pron"
          class="w-16px text-ld-brand-600 ml-1"
        />
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import { parseEnWordCard } from '@/utils/card'
import { computed, ref } from 'vue'

import type { EnWordCard } from '@/types/core'
import { playPron } from '../ConcreteCard/EnWordCardFace/common'
import { getHighlightSpans } from '@/utils'

const props = defineProps<{
  card: EnWordCard
}>()

const parsed = parseEnWordCard(props.card)

const showLargeIllustration = ref(false)

const usPron = computed(() => {
  const us = parsed.card.prons.find(item => item.language === 'en-US')
  return us
})

const highlightedExamples = computed(() => {
  return parsed.card.examples.map(item => {
    return {
      highlightedSpans: getHighlightSpans(item.source, parsed.card.word),
      translation: item.translation,
    }
  })
})

function onWordPlay() {
  playPron(parsed.card.word)
}

function onExamplePlay(index: number) {
  const example = parsed.card.examples[index]
  playPron(example.source)
}

function toggleIllustrationSize() {
  showLargeIllustration.value = !showLargeIllustration.value
}
</script>
<style scoped></style>
