<template>
  <div>
    <BossBattle
      :face="current"
      :npc="{ hp: face.hp, avatar: npcAvatar }"
      :defeated="defeated"
      :combo="combo"
      @event="onEvent"
      @next="onNext"
      @star="onStar"
      @done="onDone"
      @card-enter="emit('cardEnter')"
      @close="emit('close')"
    >
      <template #actions>
        <slot name="actions"></slot>
      </template>
    </BossBattle>
  </div>
</template>

<script setup lang="ts">
import { UnitEventType } from '@/types/core'
import { computed, provide } from 'vue'
import { useHotKey } from '@/hooks'
import type { CardFace, VirtualCardBoss } from '@/types/core'
import BossBattle from '../BossBattle.vue'
import type { FeedbackStar } from '../ConcreteCard/common/feedback'
import { getBossHalf } from '@/utils'

useHotKey('enter,space', () => {
  if (current.value == null) {
    onDone()
  }
})

const props = defineProps<{
  face: VirtualCardBoss
  combo: number
}>()

const emit = defineEmits<{
  event: [cardId: number, UnitEventType]
  done: []
  close: []
  star: [FeedbackStar]
  next: []
  cardEnter: []
}>()

const npcAvatar = computed(() => {
  return getBossHalf(props.face.name)
})

// 要显示的卡片
const current = computed(() => {
  // boss 卡片 隐藏角色形象
  return props.face.cardFace != null
    ? ({
        ...props.face.cardFace,
        style: {
          ...props.face.cardFace?.style,
          hideRoleImage: true,
        },
      } as CardFace)
    : undefined
})

const defeated = computed(() => props.face.hp === 0)

// 传入 boss name
// boss 阶段/挑战模式 问题顶部的间距改为 8px
provide('bossName', props.face.name)

function onDone() {
  emit('done')
}
function onStar(star: FeedbackStar) {
  emit('star', star)
}

function onNext() {
  emit('next')
}

function onEvent(_: number | undefined, event: UnitEventType) {
  if (current.value != null) {
    emit('event', current.value.cardId, event)
  }
}
</script>

<style scoped></style>
