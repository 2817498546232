<template>
  <div class="h-[var(--ld-viewport-height)] g-safe-area">
    <LessonEndView
      class="h-full bg-ld-background"
      :stat="mockStat"
      :mode="AtlasStageMode.PASSIONATE"
      @continue="onContinue"
    />
  </div>
</template>

<script setup lang="ts">
import { AtlasStageMode } from '@/api/atlas'
import type { UnitCompleteStat } from '@/api/learn'
import LessonEndView from '@/components/LessonEndView.vue'
import { useRouter } from 'vue-router'
const router = useRouter()

const mockStat: UnitCompleteStat = {
  unitId: 0,
  debutCount: 5,
  reviewCount: 5,
  comboCount: 10,
  duration: 300,
  exp: 20,
  comboExp: 5,
  star: 3,
  checkInPopped: true,
}

function onContinue() {
  router.back()
}
</script>

<style scoped></style>
