<template>
  <div
    :class="[
      'flex-1 overflow-hidden flex flex-col h-full bg-ld-background gap-4',
      activePage.fullscreen ? 'pt-2' : 'px-4 pt-2',
    ]"
  >
    <template v-if="!activePage.fullscreen">
      <div class="flex items-center">
        <i
          v-if="props.navigator === 'page'"
          :class="['pi pi-chevron-left cursor-pointer mr-4']"
          @click="onBack"
        ></i>

        <div class="flex flex-1 search-bar">
          <Icon
            name="search"
            class="w-20px"
          />
          <InputText
            ref="searchInput"
            v-model="data.keyword"
            class="flex-1 min-w-10"
            placeholder="输入关键词按回车进行搜索"
            enterkeyhint="search"
            @keydown.enter="onSearch"
          ></InputText>

          <Icon
            v-if="data.keyword.trim().length > 0"
            name="close-circle"
            class="w-20px mr-1 cursor-pointer"
            @click="onKeywordClear"
          />
        </div>

        <TextButton
          v-if="props.navigator === 'bottomSheet'"
          :label="_t('完成')"
          class="ml-4"
          @click="emit('done')"
        />

        <i
          v-else-if="props.navigator === 'modal'"
          :class="['pi pi-times cursor-pointer ml-4']"
          @click="emit('done')"
        ></i>
      </div>
    </template>

    <Loading
      v-if="data.loading"
      class="flex-1"
    ></Loading>

    <component
      :is="activePage.vnode"
      v-else
    />
  </div>
</template>
<script setup lang="ts">
import { searchPackages } from '@/api/package-source'
import { h, reactive } from 'vue'
import { computed } from 'vue'
import HomePage from './HomePage.vue'
import SearchResultPage from './SearchResultPage.vue'
import { last } from 'lodash-es'
import TextButton from '@/components/TextButton.vue'

import type { Component, VNode } from 'vue'
import type { SearchedPackage } from '@/api/package-source'
import PublicPackage from '@/components/PublicPackage.vue'
import { useRouter } from 'vue-router'

const props = withDefaults(
  defineProps<{
    // 路由方式，不同的路由方式导航逻辑会有差异
    // 参考：https://www.figma.com/design/sNi7fnbw3njn4pmRyoRkmr/Cloze-App?node-id=23073-26761&node-type=canvas&t=IQ0QbrSpmMy2MImC-0
    navigator?: 'modal' | 'bottomSheet' | 'page'
  }>(),
  {
    navigator: 'page',
  }
)

enum SearchPageName {
  Home = 'Home',

  Result = 'Result',

  PublicPackage = 'PublicPackage',
}

interface SearchPage {
  name: SearchPageName
  keyword: string
  vnode: VNode
  fullscreen: boolean
}

const router = useRouter()

const emit = defineEmits<{
  packagesUpdate: [pkgId?: number]
  packageChallenge: [pkgId: number]
  done: []
}>()

const homePage = h(HomePage, {
  onPkgClick: onPkgCardClick,
  onSearch: onKeywordLogSearch,
})

const data = reactive({
  keyword: '',
  loading: false,
  pages: [
    {
      name: SearchPageName.Home,
      vnode: homePage,
      keyword: '',
      fullscreen: false,
    },
  ] as SearchPage[],
})

const activePage = computed(() => last(data.pages) as SearchPage)

function onBack() {
  if (data.pages.length > 1) {
    data.pages.pop()
    data.keyword = activePage.value.keyword
  } else {
    emit('done')
  }
}

function onPkgCardClick(pkg: SearchedPackage) {
  let actionsSlot: Component | undefined = undefined

  if (props.navigator === 'modal') {
    actionsSlot = h('i', {
      class: 'pi pi-times cursor-pointer ml-4',
      onClick() {
        emit('done')
      },
    })
  } else if (props.navigator === 'bottomSheet') {
    actionsSlot = h(TextButton, {
      label: _t('完成'),
      class: 'ml-4',
      onClick() {
        emit('done')
      },
    })
  }

  data.pages.push({
    name: SearchPageName.PublicPackage,
    keyword: data.keyword,
    fullscreen: true,
    vnode: h(
      PublicPackage,
      {
        class: 'flex-1 overflow-hidden',
        hashId: pkg.hashId,
        showBackBtn: true,
        onBack,
        onView(pkgId: number) {
          router.push({
            name: 'package',
            params: {
              id: pkgId,
            },
          })
        },
        onChallenge(pkgId: number) {
          emit('packageChallenge', pkgId)
        },
        onAddToShelf(pkgId: number) {
          emit('packagesUpdate', pkgId)
        },
        onRemoveFromShelf() {
          emit('packagesUpdate')
        },
      },
      {
        actions: () => actionsSlot,
      }
    ),
  })
}

function onKeywordClear() {
  data.keyword = ''
  data.pages = [
    {
      name: SearchPageName.Home,
      vnode: homePage,
      keyword: '',
      fullscreen: false,
    },
  ]
}

function onKeywordLogSearch(keyword: string) {
  data.keyword = keyword

  onSearch()
}

async function onSearch(evt?: KeyboardEvent) {
  if (evt?.isComposing) return

  if (data.keyword === '') {
    data.pages = data.pages.slice(0, 1)
    return
  }

  data.loading = true

  try {
    data.pages = [
      {
        name: SearchPageName.Result,
        keyword: data.keyword,
        fullscreen: false,
        vnode: h(SearchResultPage, {
          result: await searchPackages(data.keyword),
          onPkgClick: onPkgCardClick,
        }),
      },
    ]
  } finally {
    data.loading = false
  }
}
</script>
<style scoped>
.search-bar {
  border: 1px solid var(--ld-border);
  border-radius: 4px;
  padding: 0 8px;
}

.p-inputtext {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: unset;
}
</style>
