import { CardRating } from '@/types/core'
import api from './base'

import type { CommonResponse } from './base'
import type { UserInfo } from './auth'
import type { UnitEvent } from './learn'
import type { CheckInCtRes } from './user'

export interface Unit {
  id: number
  cancelledAt: string
  completedAt: string
  createdAt: string
}

export function fetchUnits() {
  return api.get<
    never,
    {
      units: Unit[]
    }
  >('/debug/units')
}

export interface UnitLog {
  unitId: number
  cardId: number
  detail: {
    rateType: CardRating
    events: UnitEvent[]
  }
  createdAt: string
}

export function fetchUnitLogs(unitId: number) {
  return api.get<
    never,
    {
      learnLogs: UnitLog[]
    }
  >(`/debug/units/${unitId}/logs`)
}

export function loginByUserIdOrEmail(account: string) {
  return api.post<never, CommonResponse<{ token: string; user: UserInfo }>>(
    `/debug/login`,
    {
      account,
    }
  )
}

// 清空学习记录
export function learnClear() {
  return api.post<unknown, never>(`/debug/learn/clear`)
}

// 签到 debug
export function checkIn(dates: string[]) {
  return api.post<unknown, CheckInCtRes>(`/debug/check-in`, {
    dates: dates,
  })
}
