<template>
  <PreLoading
    v-if="preLoading"
    class="h-full"
  />

  <CheckInView
    v-else-if="checkInData != null && showCheckIn"
    class="h-full"
    :check-data="checkInData"
    @done="onCheckInDone"
  ></CheckInView>

  <DailyTasksView
    v-else-if="dailyTaskResponse != null && showDailyTasks"
    :response="dailyTaskResponse"
    @done="onBack"
  ></DailyTasksView>

  <template v-else-if="completedStats != null">
    <ChallengeStageEndView
      v-if="store.stageUnit?.challenge"
      class="px-24px py-16px h-full bg-ld-background"
      :stat="completedStats"
      @continue="onContinue()"
    ></ChallengeStageEndView>

    <LessonEndView
      v-else
      class="h-full bg-ld-background"
      :stat="completedStats"
      :mode="mode"
      @continue="onContinue"
      @review="onCardsReview"
    ></LessonEndView>
  </template>

  <RatioSpacedContainer
    v-else-if="store.stageUnit?.completed"
    class="text-center h-full"
  >
    <template v-if="pending">
      <Loading />

      <div class="mt-2">正在提交本次学习数据</div>
    </template>

    <template v-else>
      <div class="mb-2">网络异常，请重试</div>

      <Button
        label="重新提交"
        @click="completeReportAgain"
      ></Button>
    </template>
  </RatioSpacedContainer>

  <RatioSpacedContainer
    v-else-if="store.stageUnit == null"
    class="text-center h-full"
  >
    {{ $t('暂无正在学习的单元') }}

    <Button
      class="mt-4"
      label="返回首页"
      @click="onBackToHome"
    ></Button>
  </RatioSpacedContainer>

  <Loading
    v-else-if="pending"
    class="text-center h-full"
  ></Loading>

  <div
    v-else
    class="bg-gray-100"
  >
    <div class="flex flex-col h-[var(--ld-viewport-height)] mx-auto">
      <DuelQueue
        v-if="store.stageUnit.queueType === QueueType.Duel"
        @close="onClose"
        @left-blood-update="onLeftBloodUpdate"
      />

      <InsertQueue
        v-else
        @close="onClose"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { QueueType } from '@/types/core'
import DuelQueue from './DuelQueue/DuelQueue.vue'
import InsertQueue from './InsertQueue/InsertQueue.vue'
import { Howl } from 'howler'
import { ref, computed, onMounted, onUnmounted, provide } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import {
  handleUnitCancelled,
  completedStats,
  pending,
  completeReport,
  checkInData,
  dailyTaskResponse,
  mode,
} from './report'
import db from '@/db'
import PreLoading from './PreLoading.vue'
import { preLoadCards } from './preload'
import UnitReview from '@/pc/components/UnitReview.vue'
import MobileUnitReview from '@/mobile/pages/UnitReview.vue'
import CheckInView from '@/components/CheckInView.vue'
import LessonEndView from '@/components/LessonEndView.vue'
import ChallengeStageEndView from '@/components/ChallengeStageEndView.vue'
import DailyTasksView from './DailyTasksView.vue'
import { markPopped } from '@/api/user'

provide('onCardStart', onCardStart)
provide('onCardEnd', onCardEnd)

provide('onAudioPlay', onAudioPlay)

const store = useCommonStore()
const router = useRouter()

// 初始进来时学习单元并不一定处于可用状态，所以初始不能为 true
// 例如学习完成后刷新页面，此时单元仍为完成状态
const preLoading = ref(false)
const duelLeftBlood = ref(1)
const showDailyTasks = ref(false)

const correctSound = new Howl({
  src: ['/audio/public_correct.wav'],
  html5: true,
})

const inCorrectSound = new Howl({
  src: ['/audio/public_incorrect.wav'],
  html5: true,
})

const hitSound = new Howl({
  src: ['/audio/public_hit.MP3'],
  html5: true,
})

const canShowDailyTasksView = computed(() => {
  if (dailyTaskResponse.value == null) return false

  // 如果存在未领取的任务，不管完成未完成都要展示进度
  return dailyTaskResponse.value.tasks.some(item => !item.rewardClaimed)
})

async function preLoadUnitCards() {
  preLoading.value = true

  if (store.stageUnit == null) {
    preLoading.value = false
    return
  }

  const cards: string[] = []
  store.stageUnit.schedules.forEach(item => {
    if (!store.stageUnit!.completedCards?.includes(item.cardId)) {
      cards.push(item.content)
    }
  })

  await preLoadCards(cards)
  preLoading.value = false
}

onInit(() => {
  store.checkStageUnit()

  // 如果学习单元已经完成了，则自动上报一次即可，拿到上报完成的统计信息
  if (store.stageUnit?.completed) {
    completeReportAgain()
    return
  }

  preLoadUnitCards()
})

onMounted(() => {
  correctSound.load()
  inCorrectSound.load()

  window.onbeforeunload = () => true

  if (_global.isInsideApp) {
    // 进入学习页面后 禁用 ios 手势返回
    _bridge.disableBackGesture()
  }
})

onUnmounted(() => {
  correctSound.unload()
  inCorrectSound.unload()
  // 离开页面后 启用 ios 手势返回
  if (_global.isInsideApp) {
    _bridge.enableBackGesture()
  }
  window.onbeforeunload = null
})

onBeforeRouteLeave(async (_to, _from, next) => {
  const quitConfirmed = await quitConfirm()

  if (quitConfirmed) {
    if (!store.stageUnit?.completed) {
      handleUnitCancelled()
    }

    if (store.stageUnit) {
      delete db.unitProgressCacheV2[store.stageUnit.queueType]
      delete db.unitProgressCacheV2[QueueType.Duel]
    }

    next()
  } else {
    throw new Error('cancel quit')
  }
})

function onCardsReview() {
  if (_global.isPcMode) {
    _openDialog(UnitReview, {
      rootClass: 'min-w-600px max-w-900px min-h-600px',
      props: {
        stageId: store.stageUnit!.atlasStageId,
      },
      dialog: {
        showHeader: false,
        pt: {
          content: {
            style: 'flex:1; padding: 0px;',
          },
        },
      },
    })
  } else {
    _openDialog(MobileUnitReview, {
      fullscreenInMobile: true,
      props: {
        stageId: store.stageUnit!.atlasStageId,
      },
      dialog: {
        showHeader: false,
        pt: {
          content: {
            style: 'padding: 0px;',
          },
        },
      },
    })
  }
}

async function quitConfirm(): Promise<boolean> {
  // 因为用户可以直接通过链接进来学习页面，所以学习页面并不能保证 stageUnit 一定存在
  if (store.stageUnit == null) {
    return true
  }

  const stageUnit = store.stageUnit!

  const events = stageUnit.events
  const completeCardsCount = stageUnit.completedCards.length ?? 0

  if (stageUnit.completed) return true

  // 如果已经学习过，退出时需要提示
  const hasEvent =
    Object.values(events).some(item => item.length > 0) ||
    completeCardsCount > 0

  if (hasEvent) {
    let wantQuit = false

    await _confirm({
      scene: 'warn',
      icon: {
        name: 'energy-broken',
        type: 'svg',
      },
      content: _t('一个单元很快，再坚持一会吧！\n现在退出，面包就浪费了哦'),
      primaryText: _t('坚持一下，继续学习'),
      secondaryText: _t('放弃进度，退出学习'),
      onSecondaryClick(resolve) {
        wantQuit = true

        resolve(true)
      },
    })
    return wantQuit
  }

  return true
}

function completeReportAgain() {
  if (store.stageUnit == null) return

  if (store.stageUnit.queueType === QueueType.Duel) {
    completeReport(duelLeftBlood.value)
  } else {
    completeReport()
  }
}

function onLeftBloodUpdate(val: number) {
  duelLeftBlood.value = val
}

async function onClose() {
  onBack()
}
const showCheckIn = ref(false)
// 学习完成继续
function onContinue() {
  if (checkInData.value) {
    // 上报签到
    markPopped()
    showCheckIn.value = true
  } else if (canShowDailyTasksView.value) {
    showDailyTasks.value = true
  } else {
    onBack()
  }
}

function onCheckInDone() {
  if (canShowDailyTasksView.value) {
    showCheckIn.value = false
    showDailyTasks.value = true
  } else {
    onBack()
  }
}

function onBack() {
  if (router.canGoBack) {
    router.back()
    return
  }

  router.push({
    name: 'atlas',
    query: {
      challengeStage: store.stageUnit?.challenge?.index,
    },
  })
}

function onAudioPlay(type: 'correct' | 'incorrect' | 'hit') {
  switch (type) {
    case 'correct':
      correctSound.play()
      break
    case 'incorrect':
      inCorrectSound.play()
      break
    case 'hit':
      hitSound.play()
      break
  }
}

let cardStartTime = 0
function onCardStart() {
  cardStartTime = Math.floor(Date.now() / 1000)
}

function onCardEnd(cardId: number) {
  const end = Math.floor(Date.now() / 1000)

  if (cardStartTime > 0) {
    store.learnCard(cardId)
    store.addCardDuration(cardId, end - cardStartTime)
  }
}

function onBackToHome() {
  router.push(_global.homePage)
}
</script>
<style scoped></style>
