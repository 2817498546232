import type { Cloze, InlineNode, Content } from '@/types/core'

export type AIInput = string

export function parseAiInput(input: AIInput): Content {
  if (input === '') return []

  const result: Content = []

  const paragraphs = input.split('\n')

  for (const p of paragraphs) {
    const inlineNodes: InlineNode[] = []
    let idx = 0

    // cloze
    for (const m of p.matchAll(/(?<!\\)\{\{(.+?)::(.*?)\}\}/g)) {
      const left = p.slice(idx, m.index)

      if (left.length > 0) {
        inlineNodes.push({
          type: 'text',
          text: left,
        })
      }

      // 现在对于 ai 的输入不支持解析干扰项
      const cloze: Cloze = {
        type: 'cloze',
        group: m[1],
        text: m[2],
      }

      inlineNodes.push(cloze)

      idx = m.index! + m[0].length
    }

    if (idx < p.length) {
      inlineNodes.push({
        type: 'text',
        text: p.slice(idx),
      })
    }

    result.push({
      type: 'p',
      content: inlineNodes,
    })
  }

  return result
}

export function toAiInput(content: Content): string {
  const paragraphs: string[] = []

  for (const blockNode of content) {
    const texts: string[] = []

    if (blockNode.type === 'p') {
      for (const n of blockNode.content) {
        if (n.type === 'text') {
          texts.push(n.text)
        } else if (n.type === 'cloze') {
          texts.push(`{{${n.group}::${n.text}}}`)
        }
      }
    }

    paragraphs.push(texts.join(''))
  }

  return paragraphs.join('\n')
}
