<template>
  <div class="flex flex-col text-center">
    <RatioSpacedContainer class="flex-1 g-body-width py-4">
      <div class="flex max-w-300px">
        <LottieBox
          :autoplay="props.stat.star > 0"
          name="star"
          :loop="false"
        />

        <LottieBox
          class="relative bottom-10 mx--24px"
          :autoplay="props.stat.star > 1"
          name="star"
          :loop="false"
          :delay="500"
        />

        <LottieBox
          :autoplay="props.stat.star > 2"
          name="star"
          :loop="false"
          :delay="1000"
        />
      </div>

      <Img
        :name="npcName"
        class="w-148px mx-auto"
      />

      <div class="text-21px my-6">{{ message }}</div>

      <audio
        autoplay="true"
        style="display: none"
      >
        <source
          src="/audio/lesson_end.mp3"
          type="audio/mpeg"
        />
      </audio>

      <div class="flex w-full gap-4">
        <AnimationLabel
          text="学习时长"
          style="--color-main: var(--yellow-500)"
          :index="0"
          class="flex-1"
        >
          <Icon
            name="clock-circle"
            class="mr-1 w-25px"
          />
          <AnimationNum :value="formatLearnDuration(stat.duration)" />
        </AnimationLabel>

        <AnimationLabel
          v-if="stat.comboExp > 0"
          :text="_t(`${stat.comboCount} 连击`)"
          style="--color-main: var(--ld-brand-500)"
          :index="2"
          class="flex-1"
        >
          <Icon
            name="exp"
            class="mr-1 w-25px"
          />

          <AnimationNum :value="`${stat.exp}+${stat.comboExp}`" />
        </AnimationLabel>

        <AnimationLabel
          v-else
          :text="_t('获得经验')"
          :index="2"
          style="--color-main: var(--ld-brand-500)"
          class="flex-1"
        >
          <Icon
            name="exp"
            class="mr-1 w-25px"
          />

          <AnimationNum :value="stat.exp" />
        </AnimationLabel>
      </div>
    </RatioSpacedContainer>

    <div class="g-footer-width">
      <Button
        class="whitespace-nowrap mb-40px w-full"
        :label="_t('继续')"
        @click="onContinue"
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import AnimationNum from '@/components/AnimationNum.vue'
import AnimationLabel from '@/components/AnimationLabel.vue'
import { useCommonStore } from '@/stores'
import { computed } from 'vue'
import LottieBox from './LottieBox.vue'
import { formatLearnDuration } from '@/utils'
import type { UnitCompleteStat } from '@/api/learn'

const store = useCommonStore()

const props = defineProps<{
  stat: UnitCompleteStat
}>()

const npcName = computed(() => {
  return store.stageUnit?.challenge?.npcName
})

const message = computed(() => {
  return {
    3: _t('完美过关，恭喜你拿到全三星'),
    2: _t('还不错哦，只差一颗星了！'),
    1: _t('继续加油，下次一定可以拿更多星！'),
  }[props.stat.star]
})

const emit = defineEmits<{
  continue: []
}>()

function onContinue() {
  emit('continue')
}
</script>

<style scoped></style>
