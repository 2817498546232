import Axios from 'axios'
import db from '@/db'
import { useCommonStore } from '@/stores'

import type { AxiosError, AxiosHeaders, AxiosResponse } from 'axios'

export interface CommonResponse<T> {
  code: number
  message: string
  data: T
  extra: unknown
}

const api = Axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  function (config) {
    config.baseURL = '/api'

    if (!_global.isProd && _global.apiEnv === 'prod') {
      config.baseURL = '/prodapi'
    }

    config.headers['X-Token'] = _store.token
    config.headers['X-Client-Version'] = _global.isInsideApp
      ? _store.appInfo?.fullVersion
      : _global.gitCommit
    config.headers['X-Client-Type'] = _global.isInsideApp
      ? 'app'
      : _global.isPcMode
        ? 'pc-web'
        : 'm-web'

    config.headers['X-Client-Channel'] = _global.isInsideApp
      ? _store.appInfo?.channel
      : _global.isInsideWechat
        ? 'wechat'
        : 'normal'

    const debugHeaderValues = []

    if (db.debug.skip) {
      debugHeaderValues.push('skipVCode')
    }
    if (db.debug.forceOrderPriceToZero) {
      debugHeaderValues.push('forceOrderPriceToZero')
    }
    if (db.debug.skipEnergy) {
      debugHeaderValues.push('skipEnergy')
    }
    if (_store.debugTs) {
      debugHeaderValues.push(`futureTs=${new Date(_store.debugTs).getTime()}`)
    }

    if (debugHeaderValues.length > 0) {
      config.headers['X-Debug'] = debugHeaderValues.join(',')
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response: AxiosResponse) {
    const data = response.data

    if (typeof data === 'object') {
      data.__response = response
    }

    const h = response.headers as AxiosHeaders
    if (h.has('x-token')) {
      _store.token = h.get('x-token') as string
    }

    return data
  },
  // any status code which is not 2xx
  function (err: AxiosError) {
    // 通过 abort singal 取消请求的报错，此时不用做处理
    if (err.name === 'CanceledError') {
      throw err
    }

    // 460 表示注销中
    if (err.response?.status === 460) {
      _router.replace({
        name: 'terminating',
        query: {
          willTerminateAt: (err.response.data as any)?.data?.willTerminateAt,
        },
      })
      return
    }

    if (err.response?.status === 401 && !err.config?._ignore401) {
      const store = useCommonStore()
      store.logout()
      _message.error('用户未登录')

      err.skipSentry = true

      throw err
    }

    // 400
    if (err.response?.status === 400) {
      _message.error('[400]' + _t('看起来你提交的信息有点问题，请检查后重试。'))
      throw err
    }

    // 429
    if (err.response?.status === 429) {
      if (err.config?._custom429Msg) {
        _message.info(err.config._custom429Msg)
      } else {
        _message.error(
          '[429]' + _t('抱歉，您的请求频率超出了限制，请稍候再试。')
        )
      }
      throw err
    }

    // 500
    if (err.response?.status === 500) {
      _message.error(
        '[500]' + _t('服务器出了点小问题，正在努力修复中，请稍后再试。')
      )
      throw err
    }

    _message.error(err.message!)
    throw err
  }
)

export default api
