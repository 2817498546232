import type { EnWordCard } from '@/types/core'
import { shuffle } from 'lodash-es'

export interface Option {
  word: string
  definition: string
  isAnswer: boolean
  illustration?: string
}

const MAX_OPTIONS = 4
export function genOptions(
  card: EnWordCard,
  altCards: EnWordCard[],
  requireIllustration: boolean = false
) {
  const options: Option[] = [
    {
      word: card.word,
      definition: card.definition,
      isAnswer: true,
      illustration: card.illustration,
    },
  ]

  // 选图卡面不需要从自己的干扰项中取数据，只需要从 altCards 中取即可
  if (!requireIllustration) {
    shuffle(card.distrators)
      .slice(0, MAX_OPTIONS - 1)
      .forEach(item => {
        options.push({
          word: item.word,
          definition: item.definition,
          illustration: card.illustration,
          isAnswer: false,
        })
      })
  }

  if (options.length === 1) {
    shuffle(altCards)
      .slice(0, MAX_OPTIONS - 1)
      .forEach(item => {
        if (requireIllustration && item.illustration) {
          options.push({
            word: item.word,
            definition: item.definition,
            illustration: item.illustration,
            isAnswer: false,
          })
        }

        if (!requireIllustration) {
          options.push({
            word: item.word,
            definition: item.definition,
            illustration: item.illustration,
            isAnswer: false,
          })
        }
      })
  }

  return shuffle(options)
}

let sound: Howl | null = null
export function playPron(content: string, type = 0): Promise<void> {
  sound?.stop()
  // TODO(xunyi): 这样是否会 invalidate cache?
  sound?.unload()

  return new Promise(resolve => {
    sound = new Howl({
      src: [`https://dict.youdao.com/dictvoice?audio=${content}&type=${type}`],
      html5: true,
    })
    sound.play()
    sound.once('end', () => resolve())
  })
}

export const PRON_TIMEOUT = 2500
