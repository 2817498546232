<template>
  <div class="text-ld-label-secondary text-14px">
    Coyright © LingDuck 小灵鸭 |
    <a
      href="https://beian.miit.gov.cn/"
      target="_blank"
    >
      沪ICP备2021010097号-7
    </a>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped></style>
