<template>
  <div class="text-2xl font-bold mb-4 mx-4">选择配对</div>
  <div class="main flex-1 overflow-y-auto">
    <div class="flex flex-col gap-4 overflow-hidden">
      <div
        v-for="(item, i) in questionPairs"
        :key="i"
        :class="[
          'option',
          {
            selected: data.selectedQuestionIndex === i,
            disabled: item.questionDone,
            correct:
              data.result === 'correct' && data.selectedQuestionIndex === i,
            wrong: data.result === 'wrong' && data.selectedQuestionIndex === i,
          },
        ]"
        @click="onQuestionSelect(item, i)"
      >
        <div class="my-auto">
          <ContentRender :content="item.quesiton">
            <template #cloze="{ node }">
              <span class="cloze">
                {{ node.text }}
              </span>
            </template>
          </ContentRender>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4 overflow-hidden">
      <div
        v-for="(item, i) in answerPairs"
        :key="i"
        :class="[
          'option',
          {
            selected: data.selectedAnswerIndex === i,
            disabled: item.answerDone,
            correct:
              data.result === 'correct' && data.selectedAnswerIndex === i,
            wrong: data.result === 'wrong' && data.selectedAnswerIndex === i,
          },
        ]"
        @click="onAnswerSelect(item, i)"
      >
        <div class="my-auto">{{ item.answer }}</div>
      </div>
    </div>
  </div>

  <BottomFeedback
    v-if="data.passed"
    :star="data.star"
    @next="onDone"
  ></BottomFeedback>
</template>

<script lang="ts" setup>
import { UnitEventType } from '@/types/core'
import { computed, provide } from 'vue'
import { getContentClozes } from '@/utils/card'
import { reactive } from 'vue'
import { cloneDeep, shuffle } from 'lodash-es'
import { randomPick } from '@/utils'
import BottomFeedback from '@/components/ConcreteCard/common/BottomFeedback.vue'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'
import ContentRender from '../card-render/Content.vue'

import type {
  Cloze,
  VirtualCardMatch,
  Text,
  Content,
  InlineNode,
} from '@/types/core'

interface Pair {
  quesiton: Content
  answer: string
  questionDone: boolean
  answerDone: boolean
}

const emit = defineEmits<{
  event: [undefined, UnitEventType]
  star: [FeedbackStar]
  done: []
}>()

const props = defineProps<{
  match: VirtualCardMatch
}>()

const data = reactive({
  selectedQuestionIndex: null as number | null,
  selectedAnswerIndex: null as number | null,
  result: null as 'correct' | 'wrong' | null,
  passed: false,
  star: FeedbackStar.One,
  wrongTimes: 0,
})

// 目前只支持填空题
const cards = computed(() => {
  return props.match.cards.filter(item => item != null)
})

const pairs = computed(() => {
  const pairs: Pair[] = []

  cards.value.forEach(card => {
    const allClozes = getContentClozes(card.content)
    const quesiton: Content = cloneDeep(card.content)

    if (allClozes.length === 0) return

    // 从所有挖空中挑选一个挖空来展示
    const displayCloze = randomPick(allClozes) as Cloze

    for (const blockNode of quesiton) {
      let result: InlineNode[] = []
      for (const n of blockNode.content) {
        if (n === displayCloze) {
          result.push(displayCloze)
        } else if (n.type === 'cloze') {
          result.push({
            type: 'text',
            text: (n as Cloze).text,
          } as Text)
        } else {
          result.push(n)
        }
      }

      blockNode.content = result
    }

    pairs.push({
      quesiton: quesiton,
      answer: displayCloze.text,
      questionDone: false,
      answerDone: false,
    })
  })

  return pairs
})

const questionPairs = computed(() => shuffle(pairs.value))
const answerPairs = computed(() => shuffle(pairs.value))

function onQuestionSelect(pair: Pair, i: number) {
  if (pair.questionDone || data.result != null) return

  data.selectedQuestionIndex = i

  checkSelcted()
}

function onAnswerSelect(pair: Pair, i: number) {
  if (pair.answerDone || data.result != null) return

  data.selectedAnswerIndex = i
  checkSelcted()
}

function checkSelcted() {
  if (data.selectedAnswerIndex == null || data.selectedQuestionIndex == null)
    return

  const selectedQuestionPair = questionPairs.value[data.selectedQuestionIndex]
  const selectedAnswerPair = answerPairs.value[data.selectedAnswerIndex]

  if (selectedQuestionPair === selectedAnswerPair) {
    data.result = 'correct'
    selectedQuestionPair.questionDone = true
    selectedQuestionPair.answerDone = true
  } else if (selectedQuestionPair.answer === selectedAnswerPair.answer) {
    data.result = 'correct'
    selectedQuestionPair.questionDone = true
    selectedAnswerPair.answerDone = true
  } else {
    data.result = 'wrong'
    data.wrongTimes++
    emit('event', undefined, UnitEventType.WRONG)
  }

  setTimeout(() => {
    data.result = null
    data.selectedQuestionIndex = null
    data.selectedAnswerIndex = null
  }, 200)

  const allPairsDone = pairs.value.every(
    item => item.questionDone && item.answerDone
  )

  if (allPairsDone) {
    data.passed = true
    data.star = data.wrongTimes > 0 ? FeedbackStar.Two : FeedbackStar.Three
    emit('star', data.star)
    emit('event', undefined, UnitEventType.CORRECT)
  }
}

provide('isLargeScreen', false)
provide('onNext', onDone)

function onDone() {
  emit('done')
}
</script>

<style scoped>
.main {
  display: grid;
  gap: 8px;
  padding: 16px;
  grid-template-columns: 3fr 2fr;
}

.cloze {
  color: transparent;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  text-decoration-color: black;
}

.option {
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 4px 12px;
  box-shadow: 0px 0px 2px 0px var(--slate-300);
  background-color: white;
  border: 1px solid white;
  height: 100px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  overflow-y: auto;
}

.option :deep(.img) {
  max-height: 80px;
  max-width: 160px;
}

.option :deep(img) {
  max-height: 80px;
  max-width: 160px;
  object-fit: cover;
}

.option.disabled {
  opacity: 0.5;
}

.option.selected {
  background-color: var(--primary-50);
  border: 1px solid var(--primary-color);
}

.option.correct {
  background-color: var(--green-100);
  border: 1px solid var(--green-500);
}

.option.wrong {
  background-color: var(--red-100);
  border: 1px solid var(--red-500);
}
</style>
../ConcreteCard/common/feedback
