<template>
  <div
    v-if="!isLargeScreen"
    class="w-full p-12px"
    :style="containerStyle"
  >
    <div
      v-if="showAnalysis"
      class="analysis mb-4"
    >
      <slot></slot>
    </div>

    <div class="flex items-center mb-3 h-40px">
      <span
        class="text-3xl"
        :style="wordStyle"
      >
        {{ word }}
      </span>

      <div
        v-if="props.analysis"
        :style="wordStyle"
        :class="[
          'flex flex-col items-center ml-auto cursor-pointer',
          {
            disabled: analysisButtonDisabled,
          },
        ]"
        @click="onAnalysisToggle"
      >
        <template v-if="showAnalysis">
          <i class="pi pi-chevron-down"></i>
          <span>{{ _t('隐藏解析') }}</span>
        </template>

        <template v-else>
          <i class="pi pi-question-circle"></i>
          <span>{{ _t('查看解析') }}</span>
        </template>
      </div>

      <span
        v-else-if="props.lapseText && props.star > FeedbackStar.One"
        class="cursor-pointer ml-auto text-base text-gray-400"
        @click="onLapse"
      >
        {{ props.lapseText }}
      </span>
    </div>

    <Button
      class="w-full h-44px"
      :scene="buttonScene"
      :label="$t('cardview.next_card')"
      @click="emit('next')"
    ></Button>
  </div>

  <div
    v-else
    class="w-full px-4 flex flex-col min-h-120px py-8"
    :style="containerStyle"
  >
    <div
      v-if="showAnalysis"
      class="analysis mb-4"
    >
      <slot></slot>
    </div>

    <div class="flex items-center flex-1">
      <span
        class="text-3xl"
        :style="wordStyle"
      >
        {{ word }}
      </span>

      <div class="ml-auto flex items-center">
        <div
          v-if="props.analysis"
          :style="wordStyle"
          :class="[
            'flex flex-col items-center cursor-pointer',
            {
              disabled: analysisButtonDisabled,
            },
          ]"
          @click="onAnalysisToggle"
        >
          <template v-if="showAnalysis">
            <i class="pi pi-chevron-down"></i>
            <span>{{ _t('隐藏解析') }}</span>
          </template>

          <template v-else>
            <i class="pi pi-question-circle"></i>
            <span>{{ _t('查看解析') }}</span>
          </template>
        </div>

        <div
          v-else-if="props.lapseText && props.star > FeedbackStar.One"
          class="flex items-center"
        >
          <div class="hotkey">N</div>

          <span
            class="cursor-pointer text-base text-gray-400 ml-6px"
            @click="onLapse"
          >
            {{ props.lapseText }}
          </span>
        </div>
        <Button
          class="button w-300px ml-4"
          :scene="buttonScene"
          @click="emit('next')"
        >
          <div class="flex items-center justify-center w-full">
            <Icon
              name="hotkey-enter"
              class="mr-4px w-20px"
            />

            {{ $t('cardview.next_card') }}
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { FeedbackStar, genWord } from './feedback.ts'
import { inject } from 'vue'
import { useHotKey } from '@/hooks'
import { UnitEventType } from '@/types/core'

import type { ComputedRef } from 'vue'
import type { Content } from '@/types/core'
import { stringifyContent } from '@/utils/card'

const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onNext = inject<VoidFunction>('onNext')

const emit = defineEmits<{
  next: []
}>()

const props = defineProps<{
  star: FeedbackStar
  lapseText?: string
  analysis?: Content
}>()

if (props.lapseText) {
  useHotKey('n', () => {
    onLapse()
  })
}

const isLargeScreen = inject<ComputedRef<boolean>>('isLargeScreen')

const showAnalysis = ref(false)

const word = computed(() => genWord(props.star))

const analysisButtonDisabled = computed(
  () => props.analysis && stringifyContent(props.analysis).length > 0
)

const containerStyle = computed(() => {
  const color =
    props.star > FeedbackStar.One ? 'var(yellow-900)' : 'var(--red-50)'
  return `background-color: ${color};`
})

const wordStyle = computed(() => {
  const color =
    props.star > FeedbackStar.One ? 'var(--green-300)' : 'var(--red-500)'
  return `color: ${color};`
})

const buttonScene = computed(() => {
  return props.star > FeedbackStar.One ? 'correct' : 'wrong'
})

function onAnalysisToggle() {
  if (analysisButtonDisabled.value) {
    _message.info('该题暂无解析')
    return
  }

  showAnalysis.value = !showAnalysis.value
}

function onLapse() {
  onEvent?.(UnitEventType.LAPSE)
  onNext?.()
}
</script>

<style scoped>
.button {
  font-size: 14px;
}

.analysis {
  background: var(--opacityWhite);
  padding: 8px;
  border-radius: 8px;
  max-height: 110px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  overflow-y: auto;
}

.disabled {
  opacity: 0.4;
}

.hotkey {
  border-width: 1px;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
  border-color: var(--gray-400);
  color: var(--gray-400);
}
</style>
