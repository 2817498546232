const IMGS = import.meta.glob('../assets/images/*', { eager: true })

function preloadImage(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      resolve()
    }
    img.onerror = err => {
      reject(err)
    }
  })
}

export function preloadAssetImage(assetId: string): Promise<void> {
  return preloadImage(_global.assetUrl(assetId))
}

export function preloadLocalImage(name: string): Promise<void> {
  const asset = resolveImgFullName(name)

  if (asset) {
    return preloadImage(asset.fullpath)
  }

  return Promise.resolve()
}

// 传入图片的 name ，返回图片的完整路径
// eg:
//   input: role-1
//   output: {
//     key: ../assets/images/role-1.216.228.png
//     fullpath: /src/assets/images/role-1.216.228-CcdbqZZC.png 指纹在本地开发时没有
//   }
export function resolveImgFullName(
  name: string
): { key: string; fullpath: string } | null {
  for (const key in IMGS) {
    // 这里后面加 . 是为了防止名字中出现包含关系时会找错
    // eg: 1001 和 1001_avatar
    if (key.startsWith(`../assets/images/${name}.`)) {
      return {
        key,
        fullpath: (IMGS[key] as any).default,
      }
    }
  }

  return null
}

// 这里传入图片的实际名称或者是 assetId
// eg:
//   name: ../assets/images/role-9.216.228.png
//   assetId: /usr/50223/img/1.1600.1600.jpeg
export function parseImgSize(
  nameOrAssetId: string
): { height: number; width: number } | null {
  const nameWithExt = nameOrAssetId.split('/').pop()

  if (nameWithExt == null) {
    return null
  }

  const match = nameWithExt.match(/^[\w-_]+\.(\d+)\.(\d+)\.(\w+)$/)

  if (match) {
    const width = Number(match[1])
    const height = Number(match[2])

    return { width, height }
  }

  return null
}
