<template>
  <div>
    <div class="text-center">
      <span class="text-19px leading-24px font-semibold">{{ _t('共享') }}</span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 top-3 w-22px"
        @click="emit('done')"
      ></Icon>
    </div>

    <label
      class="block font-semibold text-15px mt-6 text-ld-label-secondary leading-none"
    >
      {{ _t('封面图') }}
    </label>

    <div class="flex items-start mt-2 w-full gap-3">
      <div class="w-100px h-100px relative cursor-pointer">
        <div v-if="loadingPkgSnapshot"></div>
        <template v-else-if="previewImgSrc">
          <img
            :src="previewImgSrc"
            class="w-full h-full object-cover rounded-lg"
            @click="onCoverUpload"
          />
          <div
            class="absolute top-0 right-0 cursor-pointer"
            @click="onRemoveCover"
          >
            <Icon
              name="close-circle-white"
              class="w-24px h-24px"
            ></Icon>
          </div>
        </template>
        <div
          v-else
          class="package-cover flex flex-col items-center justify-center gap-1"
          @click="onCoverUpload"
        >
          <Icon
            name="img"
            class="w-24px h-24px"
          ></Icon>
          {{ _t('上传图片') }}
        </div>
      </div>

      <div
        v-if="!loadingPkgSnapshot && !previewImgSrc"
        class="font-14px text-ld-label-secondary"
      >
        {{ _t('设置一个美美的封面吧') }}
      </div>
    </div>

    <label
      data-required
      class="block font-semibold text-15px mt-3 text-ld-label-secondary leading-none"
    >
      {{ _t('学科分类') }}
    </label>

    <AutoComplete
      class="w-full mt-2"
      :model-value="tagValue"
      :suggestions="filteredContentTags"
      dropdown
      optionLabel="name"
      optionGroupLabel="name"
      optionGroupChildren="children"
      placeholder="请选择内容标签"
      emptySearchMessage="未找到对应的标签"
      :pt="{
        input: { value: inputDisplay },
        panel: {
          class: 'content-tags-panel',
        },
      }"
      @complete="searchTag"
      @update:model-value="onTagChange"
      @blur="onTagSelecterBlur"
    ></AutoComplete>

    <label
      class="block font-semibold text-15px mt-3 text-ld-label-secondary leading-none"
    >
      卡包简介
    </label>
    <Textarea
      v-model="description"
      class="w-full mt-2"
      rows="4"
      autoResize
      maxlength="1000"
    />

    <template v-if="isShareOpen">
      <div class="text-15x leading-none text-ld-brand-500 font-semibold mt-3">
        {{ _t('已开启共享，可将下方链接分享给你的伙伴') }}
      </div>

      <div class="w-full mt-2 flex h-38px text-ld-label-secondary">
        <div
          class="flex-1 px-2 border-solid border-1 border-r-none rounded-l-lg flex items-center text-14px line-clamp-1"
        >
          <Icon
            name="globe"
            class="w-16px h-16px mr-2 shrink-0"
          ></Icon>
          {{ shareLink }}
        </div>
        <div
          class="px-4 cursor-pointer text-14px bg-ld-background border-solid border-1 rounded-r-lg flex items-center"
          @click="onLinkCopy"
        >
          {{ _t('复制链接') }}
        </div>
      </div>
    </template>

    <div class="mt-6">
      <div
        v-if="isShareOpen"
        class="flex gap-3"
      >
        <Button
          :label="_t('取消共享')"
          :loading="loading"
          scene="danger"
          class="flex-1"
          @click="onShareClose()"
        />
        <Button
          :label="_t('发布修改')"
          class="flex-1"
          @click="onPublish()"
        />
      </div>

      <div v-else>
        <div class="text-17px leading-[1.2] font-semibold">
          {{ _t('开启共享后，这个卡包可以被其他人搜索到、并学习') }}
        </div>
        <Button
          class="w-full mt-2"
          :label="_t('开启共享')"
          :loading="loading"
          @click="onSharePublish()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  fetchContentTags,
  fetchFrequentlyContentTags,
  fetchPackageSnapshot,
  ShareStatus,
  updatePackageShare,
  uploadPreviewImg,
} from '@/api/package-source'
import { computed } from 'vue'
import { ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import PublishInfo from './PublishInfo.vue'

import type { Package, PackageBasic } from '@/api/package-source'
import Textarea from 'primevue/textarea'
import AutoComplete from 'primevue/autocomplete'
import { findParentTag, tree2Map } from '@/utils/tag'
import type { ContentTag } from '@/types/core'
import type { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
import { pickImg } from '@/utils'

interface ContentTagOption extends ContentTag {
  parent: ContentTag
  children?: ContentTagOption[]
}

const props = defineProps<{
  package: PackageBasic
}>()

const emit = defineEmits<{
  done: []
  update: [PackageBasic]
}>()

const pkgPublicPath = computed(() => `/pkgs/${props.package.hashId}`)

const shareLink = computed(
  () => `${location.protocol}//${location.host}${pkgPublicPath.value}`
)

const { copy } = useClipboard({ source: shareLink.value })

const isShareOpen = computed(() => shareStatus.value === ShareStatus.COMMUNITY)
const shareStatus = ref(props.package.owned?.shareStatus)

const loading = ref(false)

const loadingPkgSnapshot = ref(true)

// 快照
const packageSnapshot = ref<Package>()

// 简介
const description = ref(props.package.description)

// 封面图本地文件
const imgLocalFile = ref<File>()

// 封面图 assetIds
const imgAssetIds = ref<string[]>([])
// 封面图地址
const previewImgSrc = computed(() => {
  if (imgLocalFile.value) {
    return URL.createObjectURL(imgLocalFile.value)
  }
  if (imgAssetIds.value.length > 0) {
    return _global.assetUrl(imgAssetIds.value[0])
  }

  return null
})

let cacheMap = new Map<string, ContentTagOption>()
const selectedTagKey = ref<string | undefined>(
  props.package?.contentTags[0]?.tag.key
)

const tagValue = ref<string | ContentTagOption | undefined>(
  selectedTagKey.value
)
const contentTags = ref<ContentTagOption[]>([])
const filteredContentTags = ref<ContentTagOption[]>([])

const selectedTag = computed(() => {
  if (selectedTagKey.value == null) return undefined

  return cacheMap.get(selectedTagKey.value)
})

const inputDisplay = computed(() => {
  if (typeof tagValue.value === 'string') {
    return tagValue.value
  } else if (selectedTag.value) {
    const parent = selectedTag.value.parent
    const showParentName = !['frequently', 'main'].includes(parent.key)

    return showParentName
      ? `${parent.name}/${selectedTag.value.name}`
      : selectedTag.value.name
  }

  return null
})

function getContentTagLeafs(tag: ContentTag, leafs: ContentTagOption[]) {
  if (!tag.children) return leafs

  for (const item of tag.children) {
    if (!item.children) {
      leafs.push({
        ...item,
        parent: tag,
        children: [],
      })
    } else {
      getContentTagLeafs(item, leafs)
    }
  }

  return leafs
}

function onTagChange(opt: ContentTagOption) {
  tagValue.value = opt

  // 仅当选择选项时，设置 selectedTagKey
  if (opt.key) {
    selectedTagKey.value = opt.key
  }
}

function onTagSelecterBlur() {
  tagValue.value = selectedTag.value
}

function searchTag(evt: AutoCompleteCompleteEvent) {
  const filteredOptions: ContentTagOption[] = []
  const query = evt.query.trim()

  if (!query) {
    filteredContentTags.value = [...contentTags.value]
    return
  }

  for (const parent of contentTags.value) {
    const filteredChildren = parent.children!.filter(item => {
      return item.name.includes(query)
    })
    const matched = parent.name.includes(query)

    // 当父节点匹配的时候，无需再筛选子节点
    if (matched) {
      filteredOptions.push(parent)
    } else if (filteredChildren.length > 0) {
      filteredOptions.push({
        ...parent,
        children: filteredChildren,
      })
    }
  }

  filteredContentTags.value = filteredOptions
}

onInit(() => {
  loadingPkgSnapshot.value = true
  Promise.all([
    fetchContentTags('main'),
    fetchFrequentlyContentTags('main'),
    fetchPackageSnapshot(props.package.id),
  ]).then(([{ tree }, { contentTags: frequentlyContentTags }, { pkg }]) => {
    packageSnapshot.value = pkg

    if (pkg?.previewImgs) {
      imgAssetIds.value = pkg.previewImgs
    }
    const allLeafs = getContentTagLeafs(tree, [])
    const parents: Record<string, ContentTagOption> = {}

    for (const leaf of allLeafs) {
      const parent = leaf.parent

      if (parents[parent.key]) {
        parents[parent.key].children?.push(leaf)
      } else {
        parents[parent.key] = {
          ...parent,
          parent,
          children: [leaf],
        }
      }
    }

    const frequentlyGroup: ContentTagOption = {
      key: 'frequently',
      name: _t('最近使用'),
      hidden: false,
      parent: tree,
      aliases: [],
      children: [],
    }

    frequentlyGroup.children = frequentlyContentTags.map(item => {
      const parent = findParentTag([tree], item.key)
      return {
        ...item,
        name: parent ? `${parent.name}/${item.name}` : item.name,
        parent: frequentlyGroup,
        children: [],
      }
    })

    const options = [frequentlyGroup, ...Object.values(parents)]

    contentTags.value = options
    filteredContentTags.value = options
    cacheMap = tree2Map(options)

    tagValue.value = selectedTag.value
    loadingPkgSnapshot.value = false
  })
})

async function onSharePublish() {
  // 检查是否选择了标签
  if (!selectedTagKey.value) {
    _message.info('请填写分类')
    return
  }

  loading.value = true
  try {
    // 上传封面图
    if (imgLocalFile.value) {
      const res = await uploadPreviewImg(props.package.id, imgLocalFile.value)
      if (res.code === 0) {
        imgAssetIds.value = [res.data.assetId]
      }
    }

    const res = await updatePackageShare(
      props.package.id,
      ShareStatus.COMMUNITY,
      [
        {
          key: selectedTagKey.value!,
          rootKey: 'main',
        },
      ],
      description.value,
      imgAssetIds.value
    )

    if (res.code !== 0) {
      _message.info(res.message)
      return
    } else {
      _message.info('已开启共享')
      shareStatus.value = res.data.owned?.shareStatus

      emit('update', res.data)
    }
  } finally {
    loading.value = false
  }
}

function onLinkCopy() {
  copy(shareLink.value)
  _message.info('链接已复制')
}

async function onPublish() {
  if (!selectedTagKey.value) {
    _message.info('请填写分类')
    return
  }

  _openDialog(PublishInfo, {
    title: _t('发布更新'),
    props: {
      contentTags: [
        {
          key: selectedTag.value!.key,
          name: selectedTag.value!.name,
          rootKey: 'main',
        },
      ],
      description: description.value,
      packageId: props.package.id,
      previewImgAssetIds: imgAssetIds.value,
      previewImgLocalFile: imgLocalFile.value,
      onRelease(newPkg: PackageBasic) {
        emit('update', newPkg)
      },
    },
  })
}

async function onShareClose() {
  loading.value = true
  try {
    const res = await updatePackageShare(props.package.id, ShareStatus.OFF)

    if (res.code !== 0) {
      _message.info(res.message)
    } else {
      _message.info('已关闭共享')

      emit('update', res.data)
      emit('done')
    }
  } finally {
    loading.value = false
  }
}

// 上传封面图
function onCoverUpload() {
  pickImg(_t('更改封面图')).then(file => {
    if (file) {
      imgLocalFile.value = file
    }
  })
}

function onRemoveCover() {
  imgLocalFile.value = undefined
  imgAssetIds.value = []
}
</script>

<style scoped>
.package-cover {
  width: 100px;
  height: 100px;
  border: 1px solid var(--surface-400);
  border-radius: 8px;
  border-style: dashed;
  background-color: var(--gray-100);
}

/* TODO: 移动端上 Dialog 的 z-index 比 AutoComplete 大，会导致看不到 AutoComplete 的下拉框 */
/* AutoComplete 组件 panel z-index 在初始化的时候会自己赋值 外界无法覆盖 所以需要通过 class 强制覆盖  */
:global(.content-tags-panel) {
  z-index: 10000 !important;
}
</style>
