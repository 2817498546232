<template>
  <div class="min-h-[var(--ld-viewport-height)] flex flex-col">
    <div class="hstack w-full items-center justify-center flex-1">
      <div class="flex images">
        <Img name="role-2" />
        <Img name="role-1" />
        <Img name="role-4" />
        <Img name="role-3" />
        <Img name="role-7" />
      </div>

      <div class="ml-80px min-w-356px">
        <div class="text-3xl mb-2">{{ _t('像玩游戏一样学习') }}</div>
        <div class="text-2xl mb-8 text-[var(--surface-400)]">
          {{ _t('闯关玩游戏，学习零压力') }}
        </div>

        <Button
          class="w-full mb-4"
          :label="_t('马上开始')"
          @click="onRegister"
        ></Button>
      </div>
    </div>

    <CopyrightFooter class="mx-auto mb-8" />
  </div>
</template>
<script setup lang="ts">
import CopyrightFooter from '@/components/CopyrightFooter.vue'
import { useCommonStore } from '@/stores'
import { onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const store = useCommonStore()
const router = useRouter()
const route = useRoute()

onBeforeMount(() => {
  if (store.isLoggedIn) {
    if (route.query.redirect) {
      const redirect = decodeURIComponent(route.query.redirect as string)

      router.replace(redirect)
    } else {
      router.replace({
        name: 'atlas',
      })
    }
  }
})

function onRegister() {
  router.push({
    name: 'auth',
    query: {
      redirect: route.query.redirect || undefined,
    },
  })
}
</script>
<style scoped>
.images :deep(img) {
  width: 108px;
}
.images :deep(img:nth-child(2n)) {
  position: relative;
  bottom: 40px;
}
</style>
