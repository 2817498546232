<template>
  <div
    ref="mainRef"
    class="max-w-md"
  >
    <div class="header text-center mb-4">
      <span class="text-19px leading-24px font-semibold">{{ _t('卡包') }}</span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 top-3 w-22px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div class="flex w-full">
      <IconSelect
        :icon="icon"
        :color="color"
        @select="onIconSelect"
      ></IconSelect>

      <div class="ml-4 flex-1">
        <div
          class="text-15px leading-none text-[var(--text-color-secondary)] font-semibold mb-6px"
        >
          {{ _t('名称') }}
        </div>

        <InputText
          v-model="name"
          :pt="{ root: { 'data-focus': 'true' } }"
          class="border-inputBorder bg-inputBg w-full"
          @keydown.enter="onFormSubmit"
        />
      </div>
    </div>

    <div class="mt-4">
      <div
        class="text-15px leading-none text-[var(--text-color-secondary)] font-semibold mb-2"
      >
        {{ _t('颜色') }}
      </div>

      <div class="flex gap-6px">
        <div
          v-for="item in PackageColor"
          class="w-24px h-24px border-1px border-white rounded-4px relative cursor-pointer"
          :style="{
            backgroundColor: `var(--${item}-300)`,
          }"
          @click="color = item"
        >
          <Icon
            v-if="item === color"
            name="color-selected"
            class="absolute absolute-center w-13px"
          />
        </div>
      </div>
    </div>

    <Button
      class="w-full mt-4"
      :label="isEditMode ? _t('保存') : _t('新建')"
      :disabled="!isValid"
      :loading="loading"
      @click="onFormSubmit"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  PackageColor,
  createPackage,
  updatePackage,
} from '@/api/package-source'
import { ref, onMounted, computed } from 'vue'
import IconSelect from './IconSelect.vue'

import type {
  PackageBasic,
  PackageIconEmoji,
  PackageIconImage,
} from '@/api/package-source'
import { genRandomColor, genRandomIcon } from '@/utils/package'

const props = defineProps<{
  package?: PackageBasic
  contentTagKey?: string
}>()

const emit = defineEmits<{
  done: []
  update: [PackageBasic]
  create: [PackageBasic]
}>()

const name = ref(props.package?.name ?? '')
const icon = ref(props.package?.style.icon ?? genRandomIcon())
const color = ref(props.package?.style.themeColor ?? genRandomColor())

const isValid = computed(() => name.value !== '')
const isEditMode = computed(() => props.package != null)

function onIconSelect(newIcon: PackageIconEmoji | PackageIconImage) {
  icon.value = newIcon
}

const loading = ref(false)

function onFormSubmit() {
  if (!isValid.value) return

  if (isEditMode.value) {
    onEditPkg()
  } else {
    onCreatePkg()
  }
}

function onEditPkg() {
  if (props.package == null) return

  loading.value = true

  updatePackage(props.package.id, {
    name: name.value.trim(),
    style: {
      themeColor: color.value,
      icon: icon.value,
    },
  })
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      _message.success('更新成功')
      emit('update', res.data)
      emit('done')
    })
    .finally(() => {
      loading.value = false
    })
}

function onCreatePkg(evt?: KeyboardEvent) {
  if (evt?.isComposing) return

  if (!isValid.value) return

  loading.value = true

  createPackage(
    name.value.trim(),
    {
      themeColor: color.value,
      icon: icon.value,
    },
    []
  )
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      _message.success('创建成功')
      emit('create', res.data)
      emit('done')
    })
    .finally(() => {
      loading.value = false
    })
}

// auto focus
const mainRef = ref()
onMounted(() => {
  setTimeout(() => {
    mainRef.value.querySelector('input[data-focus]').focus()
  }, 0)
})
</script>

<style scoped></style>
