import { defineStore } from 'pinia'
import db from '@/db'
import i18n, { SUPPORT_LOCALES, loadLocaleMessages, t } from '@/i18n'

import type { DB } from '@/db'
import type { Locale } from '@/i18n'
import { UserRole, type UserInfo } from '@/api/auth'
import { genAiCards, type CardResponse } from '@/api/package-source'
import type { CardSchedule, Unit, UnitEvent } from '@/api/learn'
import bus, { BusEvent } from '@/bus/bus'
import { fetchUnreadMsgCount } from '@/api/msg'
import { watch } from 'vue'
import { QueueType, type Content } from '@/types/core'
import {
  checkInCt,
  userAssets,
  type CheckInCtRes,
  type UserAssets,
  type EnergyResponse,
  fetchEnergy,
  type UserVipInfo,
  fetchUserVip,
  fetchVipLeftFreeTimes,
} from '@/api/user'
import { fetchUnreceivedTaskCount } from '@/api/task'
import dayjs from 'dayjs'
import { fetchStatsOverview, type OverviewStats } from '@/api/stats'
import {
  fetchDiamondCard,
  fetchStoreProducs,
  type DiamondCardResponse,
  type StoreConfig,
} from '@/api/product'
import { Code } from '@/api/code'
import { genAiSessionId, randomString, validateAiKeypoint } from '@/utils'
import { parseAiInput, type AIInput, toAiInput } from '@/utils/aiInput'
import type { CommonResponse } from '@/api/base'
import { PresetClozeGroup } from '@/components/ClozeEditor/extensions/cloze'
import { Platform, type AppInfo } from '@/utils/bridge'

export type Theme = 'light' | 'dark'

export enum PackageMode {
  Challenge,
  Browser,
}

export type AiGenerateState = {
  sessionId: string
  content: string
  loading: boolean
  keypoints: {
    // id 的目的是标记这个知识点，因为有编辑器可以修改，需要唯一标识保证编辑器不被刷新
    id: string
    content: Content
  }[]
  selectedKeypoints: {
    id: string
    content: Content
  }[]
}

// 每张卡片学习时最多计时多久，单位为秒
const MAX_CARD_DURATION = 60

// 单元学习缓存只保留一小时
const UNIT_TIMEOUT = 3600

let energyTimer: number

let aiGenerateAbortController: AbortController

export const useCommonStore = defineStore('common', {
  state: () => ({
    theme: db.theme,
    token: db.loginToken,
    user: null as UserInfo | null,
    cardEditTab: 0,
    packageState: db.packageState,

    // 用来做卡片更新的通知，每次更新卡片会修改该字段
    cardEditEvent: null as [number, CardResponse] | null,

    // 当前正在学习的关卡单元信息
    stageUnit: db.stageUnit,

    guest: db.guest,

    // 未读消息数量
    unreadMsgCount: 0,

    // 可领取但未领取的任务数量
    unreceivedTaskCount: 0,

    cardResponseCache: {} as Record<number, CardResponse | CardSchedule>,
    // 连续签到数据
    checkInCtRes: null as CheckInCtRes | null,

    // 用户资产信息
    userAssets: null as UserAssets | null,

    // 个人页面 统计信息
    statsOverview: null as OverviewStats | null,

    energy: null as EnergyResponse | null,
    energyCountDown: null as number | null,

    // 会员能够免费补充体力的次数，非会员固定为 0
    vipEnergyFreeTimes: 0,

    debugTs: null as string | null,

    vip: null as UserVipInfo | null,

    diamondCard: null as DiamondCardResponse | null,

    storeConfig: null as StoreConfig | null,

    // ai 生成卡片
    aiGenerate: {
      sessionId: genAiSessionId(),
      content: '',
      loading: false,
      keypoints: [],
      selectedKeypoints: [],
    } as AiGenerateState,

    // 编辑器中所选的挖空 group，by card 缓存
    editorSelectedClozeGroup: new Map<number, PresetClozeGroup>(),

    // app 信息
    appInfo: null as AppInfo | null,
  }),

  getters: {
    locale() {
      return i18n.global.locale as Locale
    },

    localeName() {
      const locale = this.locale as string
      return SUPPORT_LOCALES.find(v => v.value === locale)!.name
    },

    isLightTheme(): boolean {
      return this.theme === 'light'
    },

    isDarkTheme(): boolean {
      return this.theme === 'dark'
    },

    isLoggedIn(): boolean {
      return this.user != null
    },
    isEnergyFull(): boolean {
      if (this.energy == null) return false

      return this.energy.currentEnergy >= this.energy.energyLimit
    },

    isVipValid(): boolean {
      if (this.vip == null) {
        return false
      }

      const { expireAt } = this.vip

      const isExpired = dayjs(expireAt).isBefore(Date.now())

      return !isExpired
    },

    // 是否显示领取内测福利(没有领取过 并且 有内测福利)
    showAlphaGift(): boolean {
      return (
        this.user?.claimedGift === false &&
        this.storeConfig?.alphaGift != null &&
        this.storeConfig.alphaGift.diamond > 0
      )
    },

    // 该用户是否拥有 alphaCreator 身份
    isAlphaCreator(): boolean {
      if (this.user == null) return false

      return this.user.roles.includes(UserRole.AlphaCreator)
    },

    // android 审核中
    isAndroidReviewing(): boolean {
      return (
        this.appInfo?.platform === Platform.Android && this.appInfo.isReviewing
      )
    },
    // ios 审核中
    isIOSReviewing(): boolean {
      return this.appInfo?.platform === Platform.IOS && this.appInfo.isReviewing
    },

    // 上一张卡片的引导是否完成
    isLastCardGuideCompleted(): boolean {
      if (this.user == null) return false

      const userCache = db.userCache[this.user.id]

      return userCache?.lastCardGuideCompleted ?? false
    },

    // 上一张卡片的引导是否完成
    isPassionateModeGuideCompleted(): boolean {
      if (this.user == null) return false

      const userCache = db.userCache[this.user.id]

      return userCache?.passionateModeGuideCompleted ?? false
    },

    // 狂热学习
    passionateMode(): boolean {
      if (this.user == null) return false

      const userCache = db.userCache[this.user.id]

      return userCache?.passionateMode ?? false
    },
  },

  actions: {
    async setLocale(locale: Locale) {
      if (locale === this.locale) return

      if (i18n.global.availableLocales.includes(locale)) {
        i18n.global.locale = locale
        db.locale = locale
        return
      }

      await loadLocaleMessages(locale)
      i18n.global.locale = locale
      db.locale = locale
    },

    updatePackageState(
      packageId: number,
      {
        expandedKeys,
        selectedChapterId,
        selectedCardId: selectedCardId,
      }: Partial<DB['packageState'][number]>
    ) {
      this.packageState[packageId] = this.packageState[packageId] ?? {}

      if (expandedKeys) {
        this.packageState[packageId].expandedKeys = expandedKeys
      }

      if (selectedChapterId) {
        this.packageState[packageId].selectedChapterId = selectedChapterId
      }
      this.packageState[packageId].selectedCardId = selectedCardId
    },

    setCardEditTab(tab: number) {
      this.cardEditTab = tab
    },

    updateCard(id: number, card: CardResponse) {
      this.cardEditEvent = [id, card]
    },

    checkStageUnit(): boolean {
      if (this.stageUnit) {
        const seconds = Math.floor(
          (Date.now() - this.stageUnit.updatedAt) / 1000
        )

        if (seconds > UNIT_TIMEOUT) {
          this.clearStageUnit()
          return false
        }

        if (this.stageUnit.completed) {
          return false
        }

        return true
      }

      return false
    },

    setStageUnit(unit: Unit) {
      bus.emit(BusEvent.StageUnitClear)

      this.stageUnit = {
        ...unit,
        queueType: QueueType.Insert,
        updatedAt: Date.now(),
        completedCards: [],
        completed: false,
        events: {},
        cardDurations: {},
        cardLearnTimes: {},
        maxComboCount: 0,
        currentComboCount: 0,
      }
    },

    setChallengeStageUnit(
      unit: Unit,
      challenge: {
        index: number
        npcName: string
      }
    ) {
      bus.emit(BusEvent.StageUnitClear)

      this.stageUnit = {
        ...unit,
        queueType: QueueType.Duel,
        challenge,
        updatedAt: Date.now(),
        completedCards: [],
        completed: false,
        events: {},
        cardDurations: {},
        cardLearnTimes: {},
        maxComboCount: 0,
        currentComboCount: 0,
      }
    },

    clearStageUnit() {
      if (this.stageUnit) {
        delete db.unitProgressCacheV2[this.stageUnit.queueType]
      }
      delete db.unitProgressCacheV2[QueueType.Duel]

      this.stageUnit = null
    },

    completeStageUnit() {
      if (!this.stageUnit) return

      bus.emit(BusEvent.StageUnitComplete)
      this.stageUnit.completed = true
    },

    insertLearningEvent(cardId: number, event: UnitEvent) {
      if (!this.stageUnit) return

      const events = this.stageUnit.events[cardId] ?? []
      events.push(event)
      this.stageUnit.events[cardId] = events
      this.stageUnit.updatedAt = event.timestamp
    },

    completeCard(cardId: number) {
      if (!this.stageUnit) return

      this.stageUnit.completedCards = this.stageUnit.completedCards ?? []
      this.stageUnit.completedCards.push(cardId)
    },

    clearCardEvents(cardId: number) {
      if (!this.stageUnit) return

      this.stageUnit.events[cardId] = []
      this.clearCardDuration(cardId)
    },

    addCardDuration(cardId: number, second: number) {
      if (!this.stageUnit) return

      const value = this.stageUnit.cardDurations[cardId] ?? 0
      this.stageUnit.cardDurations[cardId] = Math.min(
        value + second,
        MAX_CARD_DURATION
      )
    },

    clearCardDuration(cardId: number) {
      if (!this.stageUnit) return

      delete this.stageUnit.cardDurations[cardId]
    },

    learnCard(cardId: number) {
      if (!this.stageUnit) return

      const value = this.stageUnit.cardLearnTimes[cardId] ?? 0
      this.stageUnit.cardLearnTimes[cardId] = value + 1
    },

    setComboCount(count: number) {
      if (!this.stageUnit) return

      this.stageUnit.currentComboCount = count

      if (count > this.stageUnit.maxComboCount) {
        this.stageUnit.maxComboCount = count
      }
    },

    login(token: string, user: UserInfo) {
      this.token = token
      this.user = user

      // 拉取登录需要的信息
      this.fetchUnreceivedTaskCount()
      this.fetchUnreadMsgCount()
      this.fetchEnergyStatus()
      this.fetchUserAssets()
      this.fetchCheckInCtRes()
      this.fetchStatsOverview()
      this.fetchUserVip()
      this.fetchUserDiamondCard()
      this.fetchStoreConfig()
      this.fetchVipLeftFreeTimes()

      bus.emit(BusEvent.Login)
    },

    patchUser(payload: Partial<UserInfo>) {
      if (this.user == null) return

      for (const key in payload) {
        if (key) {
          ;(this.user as any)[key] = payload[key as keyof UserInfo]
        }
      }
    },

    logout() {
      this.token = ''
      this.user = null
      this.checkInCtRes = null
      this.userAssets = null
      if (_global.isInsideApp) {
        // 退出登录时刷新 webview 可以清除路由历史
        _bridge.reloadWebview()
      }
    },

    // 获取未读消息数量
    async fetchUnreadMsgCount() {
      const res = await fetchUnreadMsgCount()
      this.unreadMsgCount = res.count
    },

    async fetchUnreceivedTaskCount() {
      const res = await fetchUnreceivedTaskCount()
      this.unreceivedTaskCount = res.count
    },

    async fetchUserVip() {
      return fetchUserVip().then(res => {
        this.vip = res.vip
      })
    },

    async fetchUserDiamondCard() {
      return fetchDiamondCard().then(res => {
        this.diamondCard = res.status
      })
    },

    async receiveDiamondCardToday() {
      if (this.diamondCard == null) return

      this.diamondCard.receivedToday = true
    },

    async fetchStoreConfig() {
      const res = await fetchStoreProducs()
      this.storeConfig = res
      await this.fetchIosProducts(res)
      return res
    },

    async fetchVipLeftFreeTimes() {
      const res = await fetchVipLeftFreeTimes()
      this.vipEnergyFreeTimes = res.leftTimes

      return this.vipEnergyFreeTimes
    },

    // ios app 内支付需要获取商品信息 没有商品信息 需要过滤
    async fetchIosProducts(storeConfig: StoreConfig) {
      if (_global.isInsideApp) {
        const platform = (await _bridge.getAppInfo()).platform
        if (platform === Platform.IOS) {
          const productIds: string[] = ['diamond_1', 'diamond_12']
          if (storeConfig.diamondList) {
            for (const item of storeConfig.diamondList) {
              if (item.appleProductId) {
                productIds.push(item.appleProductId)
              }
            }
          }
          if (storeConfig.diamondCard) {
            if (storeConfig.diamondCard.appleProductId) {
              productIds.push(storeConfig.diamondCard.appleProductId)
            }
          }
          if (storeConfig.vipList) {
            for (const item of storeConfig.vipList) {
              if (item.appleProductId) {
                productIds.push(item.appleProductId)
              }
            }
          }
        }
      }
    },

    setUnreceivedTaskCount(count: number) {
      this.unreceivedTaskCount = count
    },

    // 清空未读消息数量
    clearUnreadMsgCount() {
      this.unreadMsgCount = 0
    },

    // 未读消息数量-1
    decreaseUnreadMsgCount() {
      this.unreadMsgCount -= 1
    },

    setCardResponseCache(cardId: number, res: CardResponse | CardSchedule) {
      this.cardResponseCache[cardId] = res
    },

    // 连续签到数据
    async fetchCheckInCtRes() {
      const res = await checkInCt()
      this.checkInCtRes = res
      if (this.statsOverview) {
        this.statsOverview.accumulatedCheckInDays = res.accumulatedDays
        this.statsOverview.maxCheckInCtDays = res.maxContinuousDays
      }
      return res
    },

    //  用户资产信息
    async fetchUserAssets() {
      const res = await userAssets()
      this.userAssets = res
      if (this.statsOverview) {
        this.statsOverview.exp = res.exp
      }
      return res
    },

    async fetchEnergyStatus() {
      const res = await fetchEnergy()
      this.energy = res
      this.setEnergyCountDown()
    },
    setEnergyStatus(energy: EnergyResponse) {
      this.energy = energy
      this.setEnergyCountDown()
    },
    // 个人页面 统计信息
    async fetchStatsOverview() {
      const res = await fetchStatsOverview()
      this.statsOverview = res

      if (this.userAssets) {
        this.userAssets.exp = res.exp
      }
      if (this.checkInCtRes) {
        this.checkInCtRes.accumulatedDays = res.accumulatedCheckInDays
        this.checkInCtRes.maxContinuousDays = res.maxCheckInCtDays
      }
      return res
    },

    increaseEnergy(delta: number = 1) {
      if (this.energy == null) return
      const result = this.energy.currentEnergy + delta

      if (result > this.energy.energyLimit) {
        this.energy.currentEnergy = this.energy.energyLimit
        this.energy.nextTickAt = undefined
      } else if (result < 0) {
        this.energy.currentEnergy += 0
      } else {
        this.energy.currentEnergy = result
        this.energy.nextTickAt = dayjs()
          .add(this.energy.recoverInterval, 'minutes')
          .toString()
        this.setEnergyCountDown()
      }
    },

    setEnergyCountDown() {
      clearInterval(energyTimer)
      this.energyCountDown = null

      if (this.energy == null) return

      if (this.energyCountDown != null) return

      if (
        this.energy.nextTickAt &&
        this.energy.currentEnergy < this.energy.energyLimit
      ) {
        clearInterval(energyTimer)

        const ms = new Date(this.energy.nextTickAt).getTime() - Date.now()
        let seconds = Math.floor(ms / 1000)

        if (seconds > 0) {
          this.energyCountDown = seconds
          energyTimer = setInterval(() => {
            if (seconds <= 0) {
              clearInterval(energyTimer)

              this.increaseEnergy()
              this.energyCountDown = null
              this.setEnergyCountDown()
              return
            }

            seconds--
            this.energyCountDown = seconds
          }, 1000)
        }
      }
    },

    aiGenerateCards(content: string) {
      this.aiGenerate.loading = true
      this.aiGenerate.content = content
      aiGenerateAbortController = new AbortController()

      function isSuccess(res: CommonResponse<{ keypoints: AIInput[] }>) {
        return res.data.keypoints.filter(validateAiKeypoint).length > 0
      }

      return genAiCards(
        {
          content,
          sessionId: this.aiGenerate.sessionId,
        },
        aiGenerateAbortController.signal,
        isSuccess
      )
        .then(res => {
          if (res.code !== Code.Ok) {
            _message.info(`${res.message}，请重试`)
            return
          }

          // AI 生成时可能会出现空的知识点，需要过滤
          this.aiGenerate.keypoints = res.data.keypoints
            .filter(validateAiKeypoint)
            .map(item => {
              return {
                id: randomString(10),
                content: parseAiInput(item),
              }
            })
        })
        .finally(() => {
          this.aiGenerate.loading = false
        })
    },

    aiReGenerateCards(content: string) {
      this.aiGenerate.loading = true
      this.aiGenerate.content = content
      aiGenerateAbortController = new AbortController()

      function isSuccess(res: CommonResponse<{ keypoints: AIInput[] }>) {
        return res.data.keypoints.filter(validateAiKeypoint).length > 0
      }

      return genAiCards(
        {
          content,
          sessionId: this.aiGenerate.sessionId,
          knownKeypoints: this.aiGenerate.selectedKeypoints.map(item =>
            toAiInput(item.content)
          ),
        },
        aiGenerateAbortController.signal,
        isSuccess
      )
        .then(res => {
          if (res.code !== Code.Ok) {
            _message.info(`${res.message}，请重试`)
            return
          }

          this.aiGenerate.keypoints = res.data.keypoints
            .filter(validateAiKeypoint)
            .map(item => {
              return {
                id: randomString(10),
                content: parseAiInput(item),
              }
            })
        })
        .finally(() => {
          this.aiGenerate.loading = false
        })
    },

    abortAiGenerate() {
      aiGenerateAbortController?.abort()
      this.aiGenerate.loading = false
    },

    resetAiGenerate() {
      aiGenerateAbortController?.abort()
      this.aiGenerate = {
        sessionId: genAiSessionId(),
        content: '',
        loading: false,
        keypoints: [],
        selectedKeypoints: [],
      }
    },

    selectAiCard(index: number) {
      if (index < 0 || index > this.aiGenerate.keypoints.length) {
        return
      }

      const card = this.aiGenerate.keypoints[index]

      this.aiGenerate.keypoints.splice(index, 1)
      this.aiGenerate.selectedKeypoints.push(card)
    },

    removeAiCard(index: number) {
      if (index < 0 || index > this.aiGenerate.keypoints.length) {
        return
      }
      this.aiGenerate.keypoints.splice(index, 1)
    },

    updateAiCard(index: number, newContent: Content) {
      if (index < 0 || index > this.aiGenerate.keypoints.length) {
        return
      }

      this.aiGenerate.keypoints[index].content = newContent
    },

    unselectAiCard(index: number) {
      if (index < 0 || index > this.aiGenerate.selectedKeypoints.length) {
        return
      }

      const card = this.aiGenerate.selectedKeypoints[index]

      this.aiGenerate.selectedKeypoints.splice(index, 1)
      this.aiGenerate.keypoints.push(card)
    },

    updateSelectedAiCard(index: number, newContent: Content) {
      if (index < 0 || index > this.aiGenerate.selectedKeypoints.length) {
        return
      }

      this.aiGenerate.selectedKeypoints[index].content = newContent
    },

    updateAiContent(content: string) {
      this.aiGenerate.content = content
    },

    getCardSelectedClozeGroup(cardId?: number): PresetClozeGroup {
      if (cardId == null) return PresetClozeGroup.c1

      return this.editorSelectedClozeGroup.get(cardId) ?? PresetClozeGroup.c1
    },

    setCardSelectedClozeGroup(cardId: number, group: PresetClozeGroup) {
      this.editorSelectedClozeGroup.set(cardId, group)
    },

    // 获取 app 信息
    async getAppInfo() {
      const res = await _bridge.getAppInfo()
      this.appInfo = res
      return res
    },

    completeLastCardGuide() {
      if (this.user == null) return
      const userCache = db.userCache[this.user.id]

      if (userCache == null) {
        db.userCache[this.user.id] = {
          lastCardGuideCompleted: true,
        }
      } else {
        userCache.lastCardGuideCompleted = true
      }
    },

    completePassionateModeGuide() {
      if (this.user == null) return
      const userCache = db.userCache[this.user.id]

      if (userCache == null) {
        db.userCache[this.user.id] = {
          passionateModeGuideCompleted: true,
        }
      } else {
        userCache.passionateModeGuideCompleted = true
      }
    },

    setAtlasLearnMode(passionate: boolean) {
      if (this.user == null) return
      const userCache = db.userCache[this.user.id]

      if (userCache == null) {
        db.userCache[this.user.id] = {
          passionateMode: passionate,
        }
      } else {
        userCache.passionateMode = passionate
      }
    },

    costVipEnergyFreeTimes() {
      if (this.vipEnergyFreeTimes > 0) {
        this.vipEnergyFreeTimes--
      }
    },
  },
})

export function subscribeStore(store: CommonStore) {
  watch(
    () => [store.theme, store.token],
    function () {
      db.theme = store.theme
      db.loginToken = store.token

      if (store.theme === 'light') {
        document.documentElement.removeAttribute('theme-dark')
      } else {
        document.documentElement.setAttribute('theme-dark', 'true')
      }
    },
    { immediate: true }
  )

  watch(
    () => store.stageUnit,
    function () {
      db.stageUnit = store.stageUnit

      db.stageUnit?.schedules?.forEach(c => {
        store.setCardResponseCache(c.cardId, c)
      })
    },
    { immediate: true }
  )
}

export type CommonStore = ReturnType<typeof useCommonStore>
