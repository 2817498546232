<template>
  <div
    ref="boxRef"
    class="flex flex-col pkg-card"
  >
    <div
      :style="style"
      class="overflow-hidden flex items-center justify-center mx-auto aspect-square leading-none w-full rounded-16px"
    >
      <Img
        v-if="props.pkg.previewImgs && props.pkg.previewImgs.length"
        :assetId="props.pkg.previewImgs[0]"
        imgStyle="l"
        class="w-full h-full"
      />
      <PkgIcon
        v-else
        :style="props.pkg.style"
        class="w-full h-full"
      />
    </div>

    <div
      v-if="props.highlight"
      class="title"
      v-html="props.pkg.title"
    ></div>

    <div
      v-else
      class="title"
    >
      {{ props.pkg.title }}
    </div>

    <div class="flex items-center mt-1">
      <Avatar
        :avatar="props.pkg.authorInfo.avatar"
        imgStyle="xs"
        class="mr-1 author-avatar"
      />
      <span class="text-gray-400 text-13px leading-none">
        {{ props.pkg.authorInfo.nickname }}
      </span>
    </div>

    <DebugLabel
      :text="`uid: ${props.pkg.authorInfo.id}`"
      class="text-sm"
    />

    <DebugLabel>
      <div class="text-xs">id：{{ props.pkg.id }}</div>
      <div
        v-if="props.pkg.firstReleasedAt"
        class="text-xs"
      >
        首次发布：{{ $f.date(props.pkg.firstReleasedAt) }}
      </div>
      <div
        v-if="props.pkg.lastSnapshotAt"
        class="text-xs"
      >
        最近快照：{{ $f.date(props.pkg.lastSnapshotAt) }}
      </div>
      <div class="text-xs">卡册评分：{{ props.pkg.rankScore }}</div>
    </DebugLabel>
  </div>
</template>

<script setup lang="ts">
import type { SearchedPackage } from '@/api/package-source'
import PkgIcon from '@/pages/Atlas/PkgIcon.vue'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    highlight?: boolean
    pkg: SearchedPackage
    iconFontSize?: string
  }>(),
  {
    highlight: false,
    iconFontSize: '54px',
  }
)

const color = computed(() => props.pkg.style.themeColor)
const boxRef = ref<HTMLElement | null>(null)

const style = computed(() => {
  return {
    'background-color': `var(--${color.value}-200)`,
    'font-size': props.iconFontSize,
  }
})
</script>

<style scoped>
.pkg-card {
  padding: 12px;
  border-radius: 16px;
  border: 1px solid var(--ld-border);
  min-width: 144px;
  max-width: 224px;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 8px;
}

.title :deep(em) {
  color: var(--primary-color);
  font-style: unset;
}
.author-avatar {
  width: 16px;
  height: 16px;
}
</style>
