<template>
  <div
    class="menu-trigger"
    @click.stop="toggle"
  >
    <i class="pi pi-ellipsis-h"></i>
  </div>

  <OverlayPanel ref="menuOp">
    <div class="flex flex-col gap-4">
      <div
        v-if="!props.root"
        class="cursor-pointer"
        @click="onRenameChapter"
      >
        {{ _t('重命名') }}
      </div>
      <div
        v-if="!props.root"
        class="cursor-pointer text-red-500"
        @click="onDeleteChapter"
      >
        {{ _t('删除') }}
      </div>
    </div>
  </OverlayPanel>
</template>

<script lang="ts" setup>
import bus, { BusEvent } from '@/bus/bus'
import OverlayPanel from '@/components/OverlayPanel.vue'
import { onMounted, onUnmounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    root?: boolean
  }>(),
  {
    root: false,
  }
)

const emit = defineEmits<{
  rename: []
  delete: []
}>()

const menuOp = ref()

const toggle = (event: MouseEvent) => {
  menuOp.value.toggle(event)

  if (menuOp.value.visible) {
    bus.emit(BusEvent.OverlayPanelShow, menuOp.value)
  }
}

function onOtherMenuOpen(op: any) {
  if (op !== menuOp.value) {
    menuOp.value.hide()
  }
}

onMounted(() => {
  bus.on(BusEvent.OverlayPanelShow, onOtherMenuOpen)
})

onUnmounted(() => {
  bus.off(BusEvent.OverlayPanelShow, onOtherMenuOpen)
})

function onRenameChapter() {
  emit('rename')

  menuOp.value.hide()
}

function onDeleteChapter() {
  emit('delete')

  menuOp.value.hide()
}
</script>
<style scoped>
.menu-trigger {
  cursor: pointer;
}
</style>
